import { Button, Dialog, FormGroup } from "@blueprintjs/core";
import { PersonFieldPaths as paths } from "../utils/QuestionnaireStateManager.js";
import React, { useState } from "react";
import SpecificCancerQuestions from "./SpecificCancerQuestions.jsx";
import DropdownSelector from "./DropdownSelector.jsx";
import * as CancerTypes from "../utils/CancerTypes.js";
import _ from "lodash";
import uuidv4 from "uuid/v4";

export default function CancerHistory({ label, personId, stateManager }) {
  const [modalOpened, setModalOpened] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedOption, setSelectedOption] = useState({});
  const [qualifiers, setQualifiers] = useState([]);
  const cancers = stateManager.getPersonValue(personId, paths.CANCERS);

  const deleteCancerValue = (index) => {
    stateManager.removeFromArrayInPath(personId, paths.CANCERS, index);
  };

  const addDiagnosis = () => {
    stateManager.addToPersonArray(personId, paths.CANCERS, {
      id: selectedOption.id,
      label: selectedOption.label,
      isPresent: 'Y',
      qualifiers: qualifiers,
    });

    setModalOpened(false);
    resetValues();
  };

  const renderDiagnosisAdded = () => {
    return (
      cancers &&
      cancers.map((cancer, index) => {
        return (
          <div className="person-entry" key={uuidv4()}>
            <div className="cardTitle cardTitle-white cardTitle-left">
              <div className="d-flex align-items-center">
                <div className="person-entry-info">
                  <h4>{cancer.label}</h4>
                  {cancer.qualifiers &&
                    cancer.qualifiers.map((qualifier, index) => {
                      return (
                        <p className="subLabel noUppercase" key={index}>
                          {qualifier.numericAgeAtDiagnosis &&
                            `- Diagnosed at ${qualifier.numericAgeAtDiagnosis}`}
                          <span>
                            {qualifier.laterality &&
                              ` (${qualifier.laterality})`}
                          </span>
                        </p>
                      );
                    })}
                </div>
              </div>
              <div className="d-flex align-items-center">
                <Button
                  rightIcon="cross"
                  intent="danger"
                  minimal={true}
                  onClick={() => deleteCancerValue(index)}
                />
              </div>
            </div>
          </div>
        );
      })
    );
  };

  const resetValues = () => {
    setSelectedCategory({});
    setSelectedOption({});
    setQualifiers([]);
  };

  const filterCancerCategories = () => {
    let sex = stateManager.getPersonValue(personId, paths.SEX);
    let selectedCancers = stateManager.getPersonValue(personId, paths.CANCERS);
    return CancerTypes.CANCER_CATEGORIES.filter((category) => {
      let isCancersSelected = _.findIndex(selectedCancers, item => {return item.id === category.id});
      if (!category.genderRestriction) {
        return isCancersSelected === -1;
      } else {
        return category.genderRestriction === sex;
      }
    });
  };

  const handleQualifiersChange = (index, atributeValue) => {
    const qualifiersClone = [...qualifiers];
    qualifiersClone[index] = atributeValue
      ? { ...qualifiersClone[index], ...atributeValue }
      : null;
    setQualifiers(qualifiersClone.filter((item) => item));
  };

  return (
    <FormGroup label={label}>
      {renderDiagnosisAdded()}
      <Button
        icon="add"
        text="Add Diagnosis"
        minimal={true}
        intent="primary"
        className="addButton"
        onClick={() => setModalOpened(true)}
      />
      <Dialog
        isOpen={modalOpened}
        onClose={() => setModalOpened(false)}
        title="Add Diagnosis"
      >
        <div className="specificCancerQuestions">
          <FormGroup label="Category">
            <DropdownSelector
              defaultLabel="Select type"
              items={filterCancerCategories()}
              onItemSelect={(e) => {
                setSelectedOption(e);
                setSelectedCategory(e);
              }}
              noResultsOption={{
                name: "Other",
                label: "Other",
                id: null,
              }}
            ></DropdownSelector>
          </FormGroup>
          <SpecificCancerQuestions
            option={selectedCategory}
            onSelectedType={setSelectedOption}
            onQualifiers={handleQualifiersChange}
            stateManager={stateManager}
            personId={personId}
          />
          <div className="cancerDialogButtons">
            <Button
              intent="primary"
              outlined={true}
              large="true"
              text="Cancel"
              onClick={() => {
                setModalOpened(false);
                resetValues();
              }}
            />
            <Button
              type="submit"
              large="true"
              text="Add"
              intent="primary"
              rightIcon={"add"}
              onClick={() => addDiagnosis()}
              disabled={
                _.isEmpty(selectedOption) || selectedOption.label === "Other"
              }
            />
          </div>
        </div>
      </Dialog>
    </FormGroup>
  );
}
