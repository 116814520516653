import React from 'react';

function InstructionsStep () {
    return (
      <div className="bodyText">
        <ol>
          <li>
            <h4>It is okay to leave questions blank</h4>
            <p>If there is something you do not know, it is okay to leave some parts blank.  If you can get more information before the visit, we would greatly appreciate if you could do so.</p>
          </li>
          <li>
            <h4>One survey for the family</h4>
            <p>If this patient has a clinic appointment with their brothers and sisters, only fill out one survey for the family.</p>
          </li>
          <li>
            <h4>This is only the beginning</h4>
            <p>We will read the information you provide and one of our nurse specialists will call to talk more about the visit. We will also discuss your family history on the day you visit our clinic.</p>  
          </li>
          <li>
            <h4>Privacy is important</h4>
            <p> If there are any concerns you have or information you prefer not to be shared with your child, please let us know at the end of the survey.
            </p>
          </li>
          <li>
            <h4>Some question may be sensitive</h4>
            <p>This survey will ask about family members that have passed away and information related to their deaths.  If you do not feel able to answer these questions or prefer to discuss them over the phone, please feel free to skip those questions. </p>
          </li>
          <li>
            <h4>Reach out to us with questions</h4>
            <p>If you have any questions about the information in this survey or wish to speak with us directly, please email us at icvd@gosh.nhs.uk or over the phone at 020 7405 9200 ext. 5124, 5305, and 5139.</p>
          </li>
        </ol>
      </div>
    );
}

export default InstructionsStep;