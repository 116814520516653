import React, { useContext } from 'react';
import { FormGroup, InputGroup } from '@blueprintjs/core';
import { PersonFieldPaths } from '../utils/QuestionnaireStateManager.js';
import Context from '../utils/context/Context'

import { getLabel } from '../utils/helpers';
const { FIRST_NAME, LAST_NAME, LAST_NAME_AT_BIRTH } = PersonFieldPaths


function FullName({ getValue, setValue, id, personIdWithCreate, sex, disabled}){
  const { localization } = useContext(Context);
  return (
    <React.Fragment>
    <div 
    className='fullNameGroup clearfix'
    >
      <FormGroup
        label={ getLabel(localization, FIRST_NAME) }
        className="nameField"
      >
        <InputGroup
          disabled={disabled || false}
          value={getValue(id, FIRST_NAME) || ''}
          onChange={e => setValue(
            personIdWithCreate,
            FIRST_NAME,
            e.target.value)
          }
        />
      </FormGroup>
      <FormGroup
        label={ getLabel(localization, LAST_NAME) }
        className="nameField"
      >
        <InputGroup
          disabled={disabled || false}
          value={getValue(id, LAST_NAME) || ''}
          onChange={e => setValue(
            personIdWithCreate,
            LAST_NAME,
            e.target.value)
          }
        />
      </FormGroup>
      </div>
      { sex === 'F' &&
        <FormGroup label={getLabel(localization, LAST_NAME_AT_BIRTH)}>
          <InputGroup
            value={getValue(id, LAST_NAME_AT_BIRTH) || ''}
            onChange={(e) => setValue(
              personIdWithCreate,
              LAST_NAME_AT_BIRTH,
              e.target.value)
            }
          />
        </FormGroup>
      }
    </React.Fragment>
  );
}

export default FullName;