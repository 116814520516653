import React, { Component } from 'react';
import { Callout, FormGroup, MenuItem, Button } from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import _ from 'lodash';
import { getLabel } from '../utils/helpers';
import Context from '../utils/context/Context'

import ancestryOptions from '../utils/AncestryOptions.js';

import '../../node_modules/@blueprintjs/select/lib/css/blueprint-select.css';

class AncestryStep extends Component {
  static contextType = Context
  constructor(props) {
    super(props);

    this.state = {
      maternalAncestryQueryStr: "",
      paternalAncestryQueryStr: "",
    }
  }

  render() {
    return (
      <React.Fragment>
          <div className="mainLabel">
           <p>Please add one or more known countries or regions of ancestry on both sides of the family:</p>
          </div>
          {this.renderAncestryMultiSelect("maternal")}
          {this.renderAncestryMultiSelect("paternal")}
          <Callout intent="primary">
          <p className="text-size-sm m-0">Ancestry is different from nationality or citizenship, and many people have ancestors from more than one area. Some inherited heart conditions are more common in certain ethnic groups than in others. For this reason it is important for us to learn more about the countries or regions where our patients’ ancestors have come from.  We use this information when planning and interpreting genetic testing, in our risk assessments, and in clinical care. </p>
          </Callout>
      </React.Fragment>
    );
  }

  renderAncestryMultiSelect = (side) => {
    const selectedAncestryVals = this.getAncestryBySide(side) || [];
    const ancestryOptions = this.getAncestryOptions();
    const clearButton = selectedAncestryVals.length > 0
      ? <Button icon="cross" minimal={true} onClick={_.partial(this.handleClearAncestry, side)} />
      : null;

    return (
      <FormGroup
        label={ getLabel(this.context.localization, `ancestry.${side}`, 'patient') }
      >
        <MultiSelect
          items={ancestryOptions}
          selectedItems={_.map(selectedAncestryVals, this.getOptionObj)}
          itemRenderer={_.partial(this.renderAncestryItem, side)}
          tagRenderer={(optionObj) => optionObj.value}
          large={true}
          fill={true}
          tagInputProps={{
            onRemove: _.partial(this.handleAncestryItemDeselect, side),
            tagProps: {
              minimal: true
            },
            rightElement: clearButton
          }}
          popoverProps={{
            minimal: true,
          }}
          onItemSelect={_.partial(this.handleAncestryItemSelect, side)}
          itemPredicate={_.partial(this.itemPredicate, side)}
          onQueryChange={(queryStr) => {
            let newState = {};
            newState[this.getQueryStrStateKeyBySide(side)] = queryStr;
            this.setState(newState);
          }}
          query={this.state[this.getQueryStrStateKeyBySide(side)]}
        />
      </FormGroup>
    );
  }

  getAncestryOptions = () => {
    let options = _.map(ancestryOptions, this.getOptionObj);
    options.push({ type: "custom" });
    return options;
  }

  getOptionObj = (val) => {
    return { value: val, type: 'preset' };
  }

  itemPredicate = (side, query, optionObj) => {
    if (query && optionObj.type === 'custom') {
      return true; // we want the custom value item to always be rendered
    } else {
      const selectedItems = this.getAncestryBySide(side) || [];

      return (!query || optionObj.value.toLowerCase().indexOf(query.toLowerCase()) !== -1) // do a partial string match
        && !_.includes(selectedItems, optionObj.value); // exclude already selected items
    }
  }

  renderAncestryItem = (side, optionObj, { handleClick, modifiers }) => {
    if (optionObj.type === 'preset') {
      return (
        <MenuItem
          active={modifiers.active}
          key={optionObj.value}
          onClick={handleClick}
          text={optionObj.value}
          shouldDismissPopover={false}
        />
      ); 
    } else {
      const queryStr = this.state[this.getQueryStrStateKeyBySide(side)];
      
      return (
        <MenuItem
          active={modifiers.active}
          key={'custom'}
          onClick={handleClick}
          text={'Add "' + queryStr + '"'}
          icon='add'
          shouldDismissPopover={false}
        />
      ); 
    }
  }

  handleAncestryItemSelect = (side, optionObj) => {
    let ancestryVal;
    if (optionObj.type === 'custom') {
      ancestryVal = this.state[this.getQueryStrStateKeyBySide(side)];
    } else {
      ancestryVal = optionObj.value;
    }

    const ancestryPath = this.props.stateManager.getPersonAncestryPath(side);
    this.props.stateManager.addToPersonArray(this.props.person.id, ancestryPath, ancestryVal);

    // Clear the input
    let newState = {};
    newState[this.getQueryStrStateKeyBySide(side)] = "";
    this.setState(newState);
  }

  handleAncestryItemDeselect = (side, val) =>  {
    const ancestryPath = this.props.stateManager.getPersonAncestryPath(side);
    this.props.stateManager.removeFromPersonArray(this.props.person.id, ancestryPath, val);
  }

  handleClearAncestry = (side) => {
    const ancestryPath = this.props.stateManager.getPersonAncestryPath(side);
    this.props.stateManager.removeFromPersonArray(this.props.person.id, ancestryPath, null);
  }

  getAncestryBySide = (side) => {
    const ancestryPath = this.props.stateManager.getPersonAncestryPath(side);
    return this.props.stateManager.getPersonValue(this.props.person.id, ancestryPath);
  }

  getQueryStrStateKeyBySide = (side) => {
    return side + "AncestryQueryStr";
  }
}

export default AncestryStep;