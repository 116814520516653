import React, { Component } from 'react';

import { Tree, Hotkey, Hotkeys, HotkeysTarget, Icon, Tooltip, Button } from "@blueprintjs/core";
import _ from 'lodash';
import { navigate } from '@reach/router';

import saveToBackend, { finishSave, submitData } from './utils/persistance';
import Context from './utils/context/Context';
import CurrentStep from './CurrentStep';
import PopUpCard from './widgets/PopUpCard';
import classNames from 'classnames';

class Navigation extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    this.state = {
      selectedNavId: null,
      expandedNodeId: null,
      burgerMenuOpened: false
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className={this.state.burgerMenuOpened ? "burgerMenu opened" : "burgerMenu"}>
          <Button
            rightIcon={this.state.burgerMenuOpened ? "cross" : "menu"}
            intent="primary"
            minimal={true}
            large={true}
            onClick={() => this.setState({burgerMenuOpened: !this.state.burgerMenuOpened})}
          ></Button>
        </div>
        <div className={classNames("questionnaire-nav-sidebar", {open: this.state.burgerMenuOpened})}>
          <Tree
            key="sidebar"
            contents={this.getStepsAndFolders().map((step, i) => this.renderSidebarNavItem(step, i))}
            onNodeClick={(node) => this.saveAndGoToStep(node)}
            onNodeExpand={(node) => this.saveAndGoToStep(node)}
            onNodeCollapse={(node) => this.saveAndGoToStep(node)}
          />
          {this.props.children.filter((step => step.key === 'controls'))}
        </div>
        <CurrentStep
          icon={_.get(this.getCurStep(), 'props.icon')}
          navTitle={_.get(this.getCurStep(), 'props.navTitle')}
          currentStep={this.getCurStep()}
          loading={this.context.loading}
          handleMovePrevious={this.handleMovePrevious}
          canMovePrevious={this.canMovePrevious}
          isLastStep={this.isLastStep}
          saveAndMoveToNext={this.saveAndMoveToNext}
          setSubmitWarning={() => this.context.setDialogBox({show: true, type: "submitWarning" })}
        />
        {this.context.dialogBox.show && this.context.dialogBox.type === 'submitWarning' &&
        <PopUpCard
            title={"Are you ready to submit the questionnaire?"}
            intent={"danger"}
            message={"Your responses will be final and you will not be able to undo this action. You will be able to discuss or edit information with your physician at your appointment."}
            buttons={[
              { id: "cancel",
                text: "Cancel",
                intent: "none",
                action: () => this.context.setDialogBox({ show: false })
              },
              {
                id: "submit",
                text: "Submit",
                intent: "danger",
                action: () => this.submitQuestionnaire(),
                loading: this.context.loading.submit
              }
            ]}
          />
        }

        {this.context.dialogBox.show && this.context.dialogBox.type === 'showSaveError' &&
          <PopUpCard
            title={"Save Error"}
            intent={"warning"}
            message={"Please check your internet connection or try again later to ensure that your responses are captured correctly."}
            errorMessage={this.context.dialogBox.errorMessage}
            buttons={[
              { id: "proceedWithoutSaving",
                text: "Proceed without Saving",
                intent: "none",
                action: () => this.nextAction(this.proceedWithoutSaving)
              },
              {
                id: "trySaveAgain",
                text: "Try Again",
                intent: "none",
                action: this.saveAndMoveToNext
              }
            ]}
          />
        }

        {this.context.dialogBox.show && this.context.dialogBox.type === 'submitFailed' &&
          <PopUpCard
            title={"Submission Error"}
            intent={"warning"}
            message={"Please check your internet connection or try again later to ensure that your responses are captured correctly."}
            errorMessage={this.context.dialogBox.errorMessage}
            buttons={[
              {
                id: "cancel",
                text: "Cancel",
                intent: "none",
                action: () => this.context.setDialogBox({show: false})
              },
              {
                id: "trySaveAgain",
                text: "Try Again",
                intent: "none",
                action: this.submitQuestionnaire
              }
            ]}
          />
        }
      </React.Fragment>
    );
  }

  canMovePrevious = () => {
    return this.getCurStepIdx(this.getCurStep()) > 0;
  }

  isLastStep = () => {
    return !(this.getCurStepIdx(this.getCurStep()) < (this.getSteps().length - 1));
  }

  handleMovePrevious = () => {
    return this.moveSteps(-1, this.getCurStepIdx(this.getCurStep()));
  }

  handleMoveNext = (curStepId) => {
    const stepIdx = curStepId ? this.getCurStepIdx(this.getCurStep(curStepId)) : this.getCurStepIdx(this.getCurStep());
    return this.moveSteps(1, stepIdx);
  }


  //TO DO - merge saveAndMoveToNext and saveAndGoToStep to avoid repetition
  saveAndMoveToNext = () => {
    saveToBackend(
      this.props.cptState,
      this.props.setState,
      this.context.setLoading,
      () => { },
      finishSave,
      this.context.loading,
      'save'
    ).then((success) => {
      console.log(success)
      this.handleMoveNext()
    }).catch((err) => {
      console.warn(err);
      this.context.setDialogBox({
        type: "showSaveError",
        node: null,
        action: this.handleMoveNext,
        show: true,
        errorMessage: err
      })
    })
  }

  saveAndGoToStep = (node) => {
    saveToBackend(
      this.props.cptState,
      this.props.setState,
      this.context.setLoading,
      () => { },
      finishSave,
      this.context.loading,
      'save'
    ).then((success) => {
      console.log(success)
      this.setCurStep(node)
    }).catch((err) => {
      console.log(err);
      this.context.setDialogBox({
        type: "showSaveError",
        node: node,
        action: this.setCurStep,
        show: true,
        errorMessage: err
      })
    })
  }

  nextAction = () => {
    this.context.dialogBox.action(this.context.dialogBox.node)
    this.context.setDialogBox({ show: false })
  }

  submitQuestionnaire = () => {
    saveToBackend(
      this.props.cptState,
      this.props.setState,
      this.context.setLoading,
      () => { },
      submitData,
      this.context.loading,
      'submit'
    ).then(res => {
      console.log('submit success')
      this.context.setDialogBox({show: false})
      navigate('/thankyou', {replace:true})
    }).catch(err => {
      this.context.setDialogBox({ show: true, type: "submitFailed", errorMessage: err })
      console.log('submit failed', err)
    })
  }


  removePersonFromSidebar = (e, removePerson, curStepId) => {
    e.stopPropagation();
    removePerson();
    this.handleMoveNext(curStepId);
  }

  renderSidebarNavItem = (step) => {
    if (Array.isArray(step)){
      const childNodes = step.map((childNode, i) => {
        if(i !== 0){
          return {
            key: childNode.key,
            id: childNode.props.navId,
            label: childNode.props.navTitle,
            disabled: this.context.loading.save,
            isSelected: childNode === this.getCurStep(),
            secondaryLabel: childNode.props.removePerson && (
              <Tooltip content="Remove Person">
                <Icon className="sidebar-removePerson"
                      icon="cross"
                      iconSize="10px"
                      intent="danger"
                      onClick={(e) => this.removePersonFromSidebar(e, childNode.props.removePerson, childNode.props.navId)}
                />
              </Tooltip>
            ),
          }
        } else {
          return childNode
        }
      })
      const folderInfo = {
        key: childNodes[0].id,
        id: childNodes[0].id,
        label: childNodes[0].label,
        disabled: this.context.loading.save,
        isSelected: false,
        isExpanded: childNodes[0].id === this.getExpandedFolder(),
        type:'folder'
      }
      //set the first item in the array as the folder step
      childNodes.map(childNode => childNode.containingFolder = childNodes[0].id)
      //remove folder step from child node list
      childNodes.shift()
      folderInfo.childNodes = childNodes;
      return folderInfo;
    }
    return {
      key: step.key,
      id: step.props.navId,
      label: step.props.navTitle,
      disabled: this.context.loading.save,
      isSelected: step === this.getCurStep(),
    };
  }

  getExpandedFolder = () => {
    if (this.state.expandedNodeId){
      const steps = _.flattenDeep(this.getStepsAndFolders()).filter((step) => {
        return step.type === 'folder' && step.id === this.state.expandedNodeId;
      })
      return steps[0].id
    } else {
      return false
    }
  }

  getStepsAndFolders = () => {
    return _.flatten(this.props.children).filter(step => step.key !== 'controls');
  }

  getSteps = () => {
    return _.flattenDeep(this.props.children).filter(step => step.type !== 'folder' && step.key !== 'controls');
  }

  /**
   * Uses this.state.selectedNavId to return the currently selected step component instance from this.props.children.
   * this.state.selectedNavId is expected to hold a string corresponding to the navId value of the instance.
   */
  getCurStep = (selectedNavId = this.state.selectedNavId ) => {
    if (selectedNavId) {
      const steps = _.flattenDeep(this.getSteps()).filter((step) => {
        return step.type !== 'folder' && step.props[Navigation.NAV_ID_PROPNAME] === selectedNavId;
      })
      return steps[0]
    } else {
      return this.getSteps()[0];
    }
  }

  /**
   * Gets the index of the current step by looking through all steps.
   */
  getCurStepIdx = (curStep) => {
    return _.findIndex(this.getSteps(), curStep);
  }

  /**
   * Moves the current navigation step by the requested number of steps.
   *
   * @param {number} numSteps a negative or positive integer representing the number of steps to move.
   */
  moveSteps = (numSteps, curStepIdx) => {
    let newStepIdx = curStepIdx + numSteps;

    if (newStepIdx < 0 || newStepIdx >= this.getSteps().length) {
      throw new Error('New step is out of bounds');
    }
    let folderId = this.getContainingFolder(newStepIdx) || this.state.expandedNodeId;
    this.setState({
      selectedNavId: this.getSteps()[newStepIdx].props[Navigation.NAV_ID_PROPNAME],
      expandedNodeId: folderId
    });
  }

  /**
   * Sets the current step based on navigation ID of the given node.
   *
   * @param {string} node The node to grab the navId from
   */
  setCurStep = (node) => {
    if(node.type !== 'folder'){
      this.setState({
        selectedNavId: node.id,
        burgerMenuOpened: false
      });
    } else if (node.id === this.state.expandedNodeId) {
      this.setState({
        expandedNodeId: false
      });
    } else {
      this.setState({
        expandedNodeId: node.id
      });
    }
  }

  getContainingFolder = (stepIdx) => {
    const compareStep = (step, currentStep) => {
      if (Array.isArray(step)){
        return step.map((step) => compareStep(step, currentStep))
      } else {
        return step === currentStep
      }
    }

    if (this.getStepsAndFolders().indexOf(this.getSteps()[stepIdx]) === -1){
      let index = null;

      const folders = this.getStepsAndFolders()
                        .filter(step => Array.isArray(step))
                        .map(step => step.map(childNode => childNode))

      folders.map((step) =>  compareStep(step, this.getSteps()[stepIdx]))
        .forEach((isContainingStep, i) => {
          if (isContainingStep.includes(true)) {
            index = i;
          }
        })
      return folders[index][0].id
    } else {
      return false
}
  }

  renderHotkeys = () => {
    return (
      <Hotkeys>
        <Hotkey
          global={true}
          combo="left"
          label="Previous step"
          onKeyDown={this.handleMovePrevious}
          disabled={!this.canMovePrevious()}
        />
        <Hotkey
          global={true}
          combo="right"
          label="Next step"
          onKeyDown={(e) => this.handleMoveNext()}
          disabled={this.isLastStep()}
        />
      </Hotkeys>
    );
  }
}

Navigation.NAV_ID_PROPNAME = "navId";

export default HotkeysTarget(Navigation);
