import React, { Component } from 'react';
import ConditionalSection from '../widgets/ConditionalSection';
import { FormGroup, TextArea, Callout, NumericInput, RadioGroup, Radio } from '@blueprintjs/core';
import _ from 'lodash';
import uuidv4 from 'uuid/v4';

import { getFieldName, PersonFieldPaths } from '../utils/QuestionnaireStateManager.js';
import FamilyHelper, { RelationshipTypes, RelationshipPaths } from '../utils/FamilyHelper.js';

import FullName from '../widgets/FullName'
import MultiPersonEntry from '../widgets/MultiPersonEntry';
import PersonSuggest from '../widgets/PersonSuggest';
import DOBInput from '../widgets/DOBInput';

import Context from '../utils/context/Context'
import { getLabel } from '../utils/helpers';
import ConditionalSectionSwitch from '../widgets/ConditionalSectionSwitch';
import CancerHistory from '../widgets/CancerHistory';

class RelativeLinkingStep extends Component {
  static contextType = Context
  lastAlreadyEnteredRelative = null
  constructor(props) {
    super(props);

    const relativePersonIdsSeenByTeam = this.getPersonIdsOfRelatives(PersonFieldPaths.HAS_BEEN_SEEN_BY_TEAM, true);
    const relativePersonIdsCancer = this.getPersonIdsOfRelatives(PersonFieldPaths.HAS_RELATIVE_DIAGNOSED_CANCER, true);
    const relativePersonIdsUterusRemoved = this.getPersonIdsOfRelatives(PersonFieldPaths.HAS_BEEN_UTERUS_REMOVED, true);
    const relativePersonIdsOvariesRemoved = this.getPersonIdsOfRelatives(PersonFieldPaths.HAS_BEEN_OVARIES_REMOVED, true);
    const relativePersonIdsDiffGenderIdentity = this.getPersonIdsOfRelatives(PersonFieldPaths.HAS_DIFFERENT_GENDER_IDENTITY, true);
    const relativePersonIdsSeenElsewhere = this.getPersonIdsOfRelatives(PersonFieldPaths.HAS_RELATIVE_SEEN_ELSEWHERE_DESC, true);
    const relativePersonIdsPancreatitis = this.getPersonIdsOfRelatives(PersonFieldPaths.RELATIVE_HAVE_PANCREATITIS, true);
    const relativePersonIdsTumour = this.getPersonIdsOfRelatives(PersonFieldPaths.RELATIVE_HAVE_TUMOUR, true);
    const relativePersonIdsMoleNevi = this.getPersonIdsOfRelatives(PersonFieldPaths.RELATIVE_HAVE_MOLE_NEVI, true);
    const relativePersonIdsPolypsRemoved = this.getPersonIdsOfRelatives(PersonFieldPaths.RELATIVE_HAVE_POLYPS_REMOVED, true);
    
    this.state = {
      relativePersonIds: relativePersonIdsSeenByTeam.length
        ? relativePersonIdsSeenByTeam
        : [uuidv4()], // If there are no entries, create a new empty entry
      relativePersonIdsCancer: relativePersonIdsCancer.length
        ? relativePersonIdsCancer
        : [uuidv4()], // If there are no entries, create a new empty entry
      relativePersonIdsUterusRemoved: relativePersonIdsUterusRemoved.length
        ? relativePersonIdsUterusRemoved
        : [uuidv4()],
      relativePersonIdsOvariesRemoved: relativePersonIdsOvariesRemoved.length
        ? relativePersonIdsOvariesRemoved
        : [uuidv4()],
      relativePersonIdsDiffGenderIdentity: relativePersonIdsDiffGenderIdentity.length
        ? relativePersonIdsDiffGenderIdentity
        : [uuidv4()],
      relativePersonIdsSeenElsewhere: relativePersonIdsSeenElsewhere.length
        ? relativePersonIdsSeenElsewhere
        : [uuidv4()],
      relativePersonIdsPancreatitis: relativePersonIdsPancreatitis.length
          ? relativePersonIdsPancreatitis
          : [uuidv4()],
      relativePersonIdsTumour: relativePersonIdsTumour.length
        ? relativePersonIdsTumour
        : [uuidv4()],
      relativePersonIdsMoleNevi: relativePersonIdsMoleNevi.length
        ? relativePersonIdsMoleNevi
        : [uuidv4()],
      relativePersonIdsPolypsRemoved: relativePersonIdsPolypsRemoved.length
        ? relativePersonIdsPolypsRemoved
        : [uuidv4()]
    }
  }

  render() {
    const alreadyEnteredPersonIds = this.getPersonIdsOfRelatives(PersonFieldPaths.HAS_BEEN_SEEN_BY_TEAM, true, true);
    const alreadyEnteredPersonIdsCancer = this.getPersonIdsOfRelatives(PersonFieldPaths.HAS_RELATIVE_DIAGNOSED_CANCER, true, true);
    const alreadyEnteredPersonIdsUterusRemoved = this.getPersonIdsOfRelatives(PersonFieldPaths.HAS_BEEN_UTERUS_REMOVED, true, true);
    const alreadyEnteredPersonIdsOvariesRemoved = this.getPersonIdsOfRelatives(PersonFieldPaths.HAS_BEEN_OVARIES_REMOVED, true, true);
    const alreadyEnteredPersonIdsDiffGenderIdentity = this.getPersonIdsOfRelatives(PersonFieldPaths.HAS_DIFFERENT_GENDER_IDENTITY, true, true);
    const alreadyEnteredPersonIdsSeenElsewhere = this.getPersonIdsOfRelatives(PersonFieldPaths.HAS_RELATIVE_SEEN_ELSEWHERE_DESC, true, true);
    const alreadyEnteredPersonIdsPancreatitis = this.getPersonIdsOfRelatives(PersonFieldPaths.RELATIVE_HAVE_PANCREATITIS, true, true);
    const alreadyEnteredPersonIdsTumour = this.getPersonIdsOfRelatives(PersonFieldPaths.RELATIVE_HAVE_TUMOUR, true, true);
    const alreadyEnteredPersonIdsMoleNevi = this.getPersonIdsOfRelatives(PersonFieldPaths.RELATIVE_HAVE_MOLE_NEVI, true, true);
    const alreadyEnteredPersonIdsPolypsRemoved = this.getPersonIdsOfRelatives(PersonFieldPaths.RELATIVE_HAVE_POLYPS_REMOVED, true, true);

    return (
      <React.Fragment>
        <ConditionalSection
          label={getLabel(this.context.localization, 'properties.hasRelativeSeenByTeam', 'patient') }
          showWhenCondition={'Y'}
          conditionState={this.props.stateManager.getPersonValue(this.props.personId, PersonFieldPaths.PROPERTY_IS_PRESENT, {propertyIdx: { [getFieldName(PersonFieldPaths.PROPERTY_TYPE)]: 'hasRelativeSeenByTeam' }})}
          onConditionStateChange={(e) =>
            this.props.stateManager.setPersonValue(this.props.personId, PersonFieldPaths.PROPERTY_IS_PRESENT, e.target.value, {propertyIdx: { [getFieldName(PersonFieldPaths.PROPERTY_TYPE)]: 'hasRelativeSeenByTeam' }})}
          orderSchema={['Y', 'N', 'U']}
          cleanUpChildQuestions={() => {
            this.state.relativePersonIds.forEach(personId => this.removeRelativeFromPersonFieldPath(personId, 'relativePersonIds', PersonFieldPaths.HAS_BEEN_SEEN_BY_TEAM))
          }}
        >
          <label className='bp3-label'>Which individuals?</label>
          <MultiPersonEntry
            personIds={this.state.relativePersonIds}
            personLabel={() => `Previously Seen Relative`}
            getValue={this.props.stateManager.getPersonValue.bind(this.props.stateManager)}
            entryRenderer={this.renderRelativeEntry.bind(this, alreadyEnteredPersonIds, PersonFieldPaths.HAS_BEEN_SEEN_BY_TEAM, 'relativePersonIds')}
            addAndRemoveEntries={true}
            onRemovePersonEntry={personId => this.removeRelativeFromPersonFieldPath(personId, 'relativePersonIds', PersonFieldPaths.HAS_BEEN_SEEN_BY_TEAM)}
            onAddPersonEntry={() =>
              this.setState((state) => {
                state.relativePersonIds = [...state.relativePersonIds, uuidv4()];
                return state;
              })
            }
            stateManager={this.props.stateManager}
          />
        </ConditionalSection>
        <ConditionalSection
          // label={getLabel(this.context.localization, PersonFieldPaths.HAS_RELATIVE_DIAGNOSED_CANCER, 'patient') }
          label={'Has anyone in your family been diagnosed with cancer?'}
          showWhenCondition={'Y'}
          conditionState={this.props.stateManager.getPersonValue(this.props.personId, PersonFieldPaths.HAS_RELATIVE_DIAGNOSED_CANCER)}
          onConditionStateChange={(e) =>
            this.props.stateManager.setPersonValue(this.props.personId, PersonFieldPaths.HAS_RELATIVE_DIAGNOSED_CANCER, e.target.value)}
          orderSchema={['Y', 'N', 'U']}
          cleanUpChildQuestions={() => null}
        >
          <label className='bp3-label'>Which individuals?</label>
          <MultiPersonEntry
            personIds={this.state.relativePersonIdsCancer}
            personLabel={() => `Relative diagnosed with cancer`}
            focusedPersonId={this.lastAlreadyEnteredRelative}
            getValue={this.props.stateManager.getPersonValue.bind(this.props.stateManager)}
            entryRenderer={this.renderCancerRelativeEntry.bind(this, alreadyEnteredPersonIdsCancer, PersonFieldPaths.HAS_RELATIVE_DIAGNOSED_CANCER, 'relativePersonIdsCancer')}
            addAndRemoveEntries={true}
            onRemovePersonEntry={personId => this.removeRelativeFromPersonFieldPath(personId, 'relativePersonIdsCancer', PersonFieldPaths.HAS_RELATIVE_DIAGNOSED_CANCER)}
            onAddPersonEntry={() =>
              this.setState((state) => {
                state.relativePersonIdsCancer = [...state.relativePersonIdsCancer, uuidv4()];
                return state;
              })
            }
            stateManager={this.props.stateManager}
          />
        </ConditionalSection>
        <ConditionalSection
          // label={getLabel(this.context.localization, PersonFieldPaths.HAS_RELATIVE_UTERUS_REMOVED, 'patient') }
          label={'Has anyone in your family had their uterus removed (hysterectomy)?'}
          showWhenCondition={'Y'}
          conditionState={this.props.stateManager.getPersonValue(this.props.personId, PersonFieldPaths.HAS_RELATIVE_UTERUS_REMOVED)}
          onConditionStateChange={(e) =>
            this.props.stateManager.setPersonValue(this.props.personId, PersonFieldPaths.HAS_RELATIVE_UTERUS_REMOVED, e.target.value)}
          orderSchema={['Y', 'N', 'U']}
          cleanUpChildQuestions={() => {
            this.state.relativePersonIdsUterusRemoved.forEach(personId => this.removeRelativeFromPersonFieldPath(personId, 'relativePersonIdsUterusRemoved', PersonFieldPaths.HAS_BEEN_UTERUS_REMOVED))
          }}
        >
          <label className='bp3-label'>Which individuals?</label>
          <MultiPersonEntry
            personIds={this.state.relativePersonIdsUterusRemoved}
            personLabel={() => `Relative that had hystersectomy`}
            focusedPersonId={this.lastAlreadyEnteredRelative}
            getValue={this.props.stateManager.getPersonValue.bind(this.props.stateManager)}
            entryRenderer={
              this.renderAgeAndReasonRelativeEntry.bind(
                this,
                alreadyEnteredPersonIdsUterusRemoved,
                PersonFieldPaths.HAS_BEEN_UTERUS_REMOVED,
                'relativePersonIdsUterusRemoved',
                PersonFieldPaths.AGE_UTERUS_REMOVED,
                PersonFieldPaths.REASON_UTERUS_REMOVED,
              )
            }
            addAndRemoveEntries={true}
            onRemovePersonEntry={personId => this.removeRelativeFromPersonFieldPath(personId, 'relativePersonIdsUterusRemoved', PersonFieldPaths.HAS_BEEN_UTERUS_REMOVED)}
            onAddPersonEntry={() =>
              this.setState((state) => {
                state.relativePersonIdsUterusRemoved = [...state.relativePersonIdsUterusRemoved, uuidv4()];
                return state;
              })
            }
            stateManager={this.props.stateManager}
          />
        </ConditionalSection>
        <ConditionalSection
          // label={getLabel(this.context.localization, PersonFieldPaths.HAS_RELATIVE_OVARIES_REMOVED, 'patient') }
          label={'Has anyone in your family had one or both of their ovaries removed?'}
          showWhenCondition={'Y'}
          conditionState={this.props.stateManager.getPersonValue(this.props.personId, PersonFieldPaths.HAS_RELATIVE_OVARIES_REMOVED)}
          onConditionStateChange={(e) =>
            this.props.stateManager.setPersonValue(this.props.personId, PersonFieldPaths.HAS_RELATIVE_OVARIES_REMOVED, e.target.value)}
          orderSchema={['Y', 'N', 'U']}
          cleanUpChildQuestions={() => {
            this.state.relativePersonIdsOvariesRemoved.forEach(personId => this.removeRelativeFromPersonFieldPath(personId, 'relativePersonIdsOvariesRemoved', PersonFieldPaths.HAS_BEEN_OVARIES_REMOVED))
          }}
        >
          <label className='bp3-label'>Which individuals?</label>
          <MultiPersonEntry
            personIds={this.state.relativePersonIdsOvariesRemoved}
            personLabel={() => `Relative that had one or both ovaries removed`}
            focusedPersonId={this.lastAlreadyEnteredRelative}
            getValue={this.props.stateManager.getPersonValue.bind(this.props.stateManager)}
            entryRenderer={
              this.renderAgeAndReasonRelativeEntry.bind(
                this,
                alreadyEnteredPersonIdsOvariesRemoved,
                PersonFieldPaths.HAS_BEEN_OVARIES_REMOVED,
                'relativePersonIdsOvariesRemoved',
                PersonFieldPaths.AGE_OVARIES_REMOVED,
                PersonFieldPaths.REASON_OVARIES_REMOVED
              )
            }
            addAndRemoveEntries={true}
            onRemovePersonEntry={personId => this.removeRelativeFromPersonFieldPath(personId, 'relativePersonIdsOvariesRemoved', PersonFieldPaths.HAS_BEEN_OVARIES_REMOVED)}
            onAddPersonEntry={() =>
              this.setState((state) => {
                state.relativePersonIdsOvariesRemoved = [...state.relativePersonIdsOvariesRemoved, uuidv4()];
                return state;
              })
            }
            stateManager={this.props.stateManager}
          />
        </ConditionalSection>
        <div className="bp3-form-group">
          <label className="bp3-label">Have you or anyone in your family had any of the following conditions?</label>
          <ConditionalSectionSwitch
            label="Chronic pancreatitis that lasted longer than 6 months"
            showWhenCondition={'Y'}
            conditionState={this.props.stateManager.getPersonValue(this.props.personId, PersonFieldPaths.RELATIVE_HAVE_PANCREATITIS)}
            onConditionStateChange={(value) =>
              this.props.stateManager.setPersonValue(this.props.personId, PersonFieldPaths.RELATIVE_HAVE_PANCREATITIS, value)}
            cleanUpChildQuestions={() => {
              this.state.relativePersonIdsPancreatitis.forEach(personId => this.removeRelativeFromPersonFieldPath(personId, 'relativePersonIdsPancreatitis', PersonFieldPaths.HAVE_PANCREATITIS))
            }}
          >
            <label className='bp3-label'>Which individuals?</label>
            <MultiPersonEntry
              personIds={this.state.relativePersonIdsPancreatitis}
              personLabel={() => `Relative with chronic pancreatitis`}
              getValue={this.props.stateManager.getPersonValue.bind(this.props.stateManager)}
              entryRenderer={this.renderRelativeEntry.bind(this, alreadyEnteredPersonIdsPancreatitis, PersonFieldPaths.RELATIVE_HAVE_PANCREATITIS, 'relativePersonIdsPancreatitis')}
              addAndRemoveEntries={true}
              onRemovePersonEntry={personId => this.removeRelativeFromPersonFieldPath(personId, 'relativePersonIdsPancreatitis', PersonFieldPaths.HAVE_PANCREATITIS)}
              onAddPersonEntry={() =>
                this.setState((state) => {
                  state.relativePersonIdsPancreatitis = [...state.relativePersonIdsPancreatitis, uuidv4()];
                  return state;
                })
              }
              stateManager={this.props.stateManager}
            />
          </ConditionalSectionSwitch>
          <ConditionalSectionSwitch
            label="Tumour or growth in the pituitary, parathyroid or adrenal gland"
            showWhenCondition={'Y'}
            conditionState={this.props.stateManager.getPersonValue(this.props.personId, PersonFieldPaths.RELATIVE_HAVE_TUMOUR)}
            onConditionStateChange={(value) =>
              this.props.stateManager.setPersonValue(this.props.personId, PersonFieldPaths.RELATIVE_HAVE_TUMOUR, value)}
            cleanUpChildQuestions={() => {
              this.state.relativePersonIdsTumour.forEach(personId => this.removeRelativeFromPersonFieldPath(personId, 'relativePersonIdsTumour', PersonFieldPaths.HAVE_TUMOUR))
            }}
          >
            <label className='bp3-label'>Which individuals?</label>
            <MultiPersonEntry
              personIds={this.state.relativePersonIdsTumour}
              personLabel={() => `Relative with tumour`}
              getValue={this.props.stateManager.getPersonValue.bind(this.props.stateManager)}
              entryRenderer={this.renderRelativeEntry.bind(this, alreadyEnteredPersonIdsTumour, PersonFieldPaths.RELATIVE_HAVE_TUMOUR, 'relativePersonIdsTumour')}
              addAndRemoveEntries={true}
              onRemovePersonEntry={personId => this.removeRelativeFromPersonFieldPath(personId, 'relativePersonIdsTumour', PersonFieldPaths.HAVE_TUMOUR)}
              onAddPersonEntry={() =>
                this.setState((state) => {
                  state.relativePersonIdsTumour = [...state.relativePersonIdsTumour, uuidv4()];
                  return state;
                })
              }
              stateManager={this.props.stateManager}
            />
          </ConditionalSectionSwitch>
          <ConditionalSectionSwitch
            label="More than 50 moles/nevi (not freckles)"
            showWhenCondition={'Y'}
            conditionState={this.props.stateManager.getPersonValue(this.props.personId, PersonFieldPaths.RELATIVE_HAVE_MOLE_NEVI)}
            onConditionStateChange={(value) =>
              this.props.stateManager.setPersonValue(this.props.personId, PersonFieldPaths.RELATIVE_HAVE_MOLE_NEVI, value)}
            cleanUpChildQuestions={() => {
              this.state.relativePersonIdsMoleNevi.forEach(personId => this.removeRelativeFromPersonFieldPath(personId, 'relativePersonIdsMoleNevi', PersonFieldPaths.HAVE_MOLE_NEVI))
            }}
          >
            <label className='bp3-label'>Which individuals?</label>
            <MultiPersonEntry
              personIds={this.state.relativePersonIdsMoleNevi}
              personLabel={() => `Relative with more than 50 moles/nevi`}
              getValue={this.props.stateManager.getPersonValue.bind(this.props.stateManager)}
              entryRenderer={this.renderRelativeEntry.bind(this, alreadyEnteredPersonIdsMoleNevi, PersonFieldPaths.RELATIVE_HAVE_MOLE_NEVI, 'relativePersonIdsMoleNevi')}
              addAndRemoveEntries={true}
              onRemovePersonEntry={personId => this.removeRelativeFromPersonFieldPath(personId, 'relativePersonIdsMoleNevi', PersonFieldPaths.HAVE_MOLE_NEVI)}
              onAddPersonEntry={() =>
                this.setState((state) => {
                  state.relativePersonIdsMoleNevi = [...state.relativePersonIdsMoleNevi, uuidv4()];
                  return state;
                })
              }
              stateManager={this.props.stateManager}
            />
          </ConditionalSectionSwitch>
          <ConditionalSectionSwitch
            label="More than 10 polyps removed from the bowel (colon or rectum)"
            showWhenCondition={'Y'}
            conditionState={this.props.stateManager.getPersonValue(this.props.personId, PersonFieldPaths.RELATIVE_HAVE_POLYPS_REMOVED)}
            onConditionStateChange={(value) =>
              this.props.stateManager.setPersonValue(this.props.personId, PersonFieldPaths.RELATIVE_HAVE_POLYPS_REMOVED, value)}
            cleanUpChildQuestions={() => {
              this.state.relativePersonIdsPolypsRemoved.forEach(personId => this.removeRelativeFromPersonFieldPath(personId, 'relativePersonIdsPolypsRemoved', PersonFieldPaths.HAVE_POLYPS_REMOVED))
            }}
          >
            <label className='bp3-label'>Which individuals?</label>
            <MultiPersonEntry
              personIds={this.state.relativePersonIdsPolypsRemoved}
              personLabel={() => `Relative with polyps removed from the bowel`}
              getValue={this.props.stateManager.getPersonValue.bind(this.props.stateManager)}
              entryRenderer={this.renderRelativeEntry.bind(this, alreadyEnteredPersonIdsPolypsRemoved, PersonFieldPaths.RELATIVE_HAVE_POLYPS_REMOVED, 'relativePersonIdsPolypsRemoved')}
              addAndRemoveEntries={true}
              onRemovePersonEntry={personId => this.removeRelativeFromPersonFieldPath(personId, 'relativePersonIdsPolypsRemoved', PersonFieldPaths.HAVE_POLYPS_REMOVED)}
              onAddPersonEntry={() =>
                this.setState((state) => {
                  state.relativePersonIdsPolypsRemoved = [...state.relativePersonIdsPolypsRemoved, uuidv4()];
                  return state;
                })
              }
              stateManager={this.props.stateManager}
            />
          </ConditionalSectionSwitch>
        </div>
        <ConditionalSection
          label={ getLabel(this.context.localization, 'properties.hasRelativeSeenElsewhere', 'patient') }
          showWhenCondition={'Y'}
          conditionState={this.props.stateManager.getPersonValue(this.props.personId, PersonFieldPaths.PROPERTY_IS_PRESENT, {propertyIdx: { [getFieldName(PersonFieldPaths.PROPERTY_TYPE)]: 'hasRelativeSeenElsewhere' }})}
          onConditionStateChange={(e) =>
            this.props.stateManager.setPersonValue(this.props.personId, PersonFieldPaths.PROPERTY_IS_PRESENT, e.target.value, {propertyIdx: { [getFieldName(PersonFieldPaths.PROPERTY_TYPE)]: 'hasRelativeSeenElsewhere' }})}
          orderSchema={['Y', 'N' , 'U']}
          cleanUpChildQuestions={() => {
            this.props.stateManager.setPersonValue(this.props.personId,
              PersonFieldPaths.PROPERTY_IS_PRESENT,
              null,
              {propertyIdx: { [getFieldName(PersonFieldPaths.PROPERTY_TYPE)]: 'hasRelativeSeenElsewhereDesc' }})
          }}
        >
          <label className='bp3-label'>Which individuals?</label>
          <MultiPersonEntry
            personIds={this.state.relativePersonIdsSeenElsewhere}
            personLabel={() => `Previously Seen Relative`}
            focusedPersonId={this.lastAlreadyEnteredRelative}
            getValue={this.props.stateManager.getPersonValue.bind(this.props.stateManager)}
            entryRenderer={this.renderRelativeEntrySeenElsewhere.bind(this, alreadyEnteredPersonIdsSeenElsewhere)}
            addAndRemoveEntries={true}
            onRemovePersonEntry={personId => this.removeRelativeFromPersonFieldPath(personId, 'relativePersonIdsSeenElsewhere', PersonFieldPaths.HAS_RELATIVE_SEEN_ELSEWHERE_DESC)}
            onAddPersonEntry={() =>
              this.setState((state) => {
                state.relativePersonIdsSeenElsewhere = [...state.relativePersonIdsSeenElsewhere, uuidv4()];
                return state;
              })
            }
            stateManager={this.props.stateManager}
          />
        </ConditionalSection>
        <ConditionalSection
          // label={getLabel(this.context.localization, PersonFieldPaths.HAS_RELATIVE_DIFFERENT_GENDER_IDENTITY, 'patient') }
          label={'Is there anyone in your family who has a different gender identity than the sex they were assigned at birth?'}
          showWhenCondition={'Y'}
          conditionState={this.props.stateManager.getPersonValue(this.props.personId, PersonFieldPaths.HAS_RELATIVE_DIFFERENT_GENDER_IDENTITY)}
          onConditionStateChange={(e) =>
            this.props.stateManager.setPersonValue(this.props.personId, PersonFieldPaths.HAS_RELATIVE_DIFFERENT_GENDER_IDENTITY, e.target.value)}
          orderSchema={['Y', 'N']}
          cleanUpChildQuestions={() => {
            this.state.relativePersonIdsDiffGenderIdentity.forEach(personId => this.removeRelativeFromPersonFieldPath(personId, 'relativePersonIdsDiffGenderIdentity', PersonFieldPaths.HAS_DIFFERENT_GENDER_IDENTITY))
          }}
        >
          <label className='bp3-label'>Which individuals?</label>
          <MultiPersonEntry
            personIds={this.state.relativePersonIdsDiffGenderIdentity}
            personLabel={() => `Relative with different gender identity`}
            focusedPersonId={this.lastAlreadyEnteredRelative}
            getValue={this.props.stateManager.getPersonValue.bind(this.props.stateManager)}
            entryRenderer={this.renderGenderRelativeEntry.bind(this, alreadyEnteredPersonIdsDiffGenderIdentity, PersonFieldPaths.HAS_DIFFERENT_GENDER_IDENTITY)}
            addAndRemoveEntries={true}
            onRemovePersonEntry={personId => this.removeRelativeFromPersonFieldPath(personId, 'relativePersonIdsDiffGenderIdentity', PersonFieldPaths.HAS_DIFFERENT_GENDER_IDENTITY)}
            onAddPersonEntry={() =>
              this.setState((state) => {
                state.relativePersonIdsDiffGenderIdentity = [...state.relativePersonIdsDiffGenderIdentity, uuidv4()];
                return state;
              })
            }
            stateManager={this.props.stateManager} 
          />
        </ConditionalSection>
        <Callout intent="primary">
          <p className="text-size-sm m-0">It is helpful if we are able to link records of people who attend the clinic for family history purposes.</p>
        </Callout>
      </React.Fragment>
    );
  }

  removeRelativeFromPersonFieldPath = (personId, stateProp, personFieldPath) => {
    const alreadyEnteredPersonIds = this.getPersonIdsOfRelatives(personFieldPath, true, true);
    this.setState((state) => {
      state[stateProp] = _.without(state[stateProp], personId);
      return state;
    });
    // Only remove the actual person if it was entered here manually (as opposed to selected from the
    // suggest field)
    if (_.includes(alreadyEnteredPersonIds, personId)) {
      this.props.stateManager.setPersonValue(this.props.personId, PersonFieldPaths.PROPERTY_IS_PRESENT, undefined, {propertyIdx: { [getFieldName(PersonFieldPaths.PROPERTY_TYPE)]: personFieldPath }});
    } else {
      this.props.stateManager.removePerson(personId);
    }
  }

  renderRelativeEntry =(alreadyEnteredPersonIds, personFieldPath, stateProp, relativePersonId) => {
    const relativePersonIdWithCreate = _.partial(this.resolveRelativePersonIdWithCreate, relativePersonId);
    const isAlreadyEntered = _.includes(alreadyEnteredPersonIds, relativePersonId);

    return (
      <React.Fragment>
        {this.renderRelativeIdentity(relativePersonId, relativePersonIdWithCreate, isAlreadyEntered, personFieldPath, stateProp)}
      </React.Fragment>
    );
  }

  renderCancerRelativeEntry = (alreadyEnteredPersonIds, personFieldPath, stateProp, relativePersonId) => {
    const relativePersonIdWithCreate = _.partial(this.resolveRelativePersonIdWithCreate, relativePersonId);
    const isAlreadyEntered = _.includes(alreadyEnteredPersonIds, relativePersonId);

    return (
      <React.Fragment>
        {this.renderRelativeIdentity(relativePersonId, relativePersonIdWithCreate, isAlreadyEntered, personFieldPath, stateProp)}
        <CancerHistory
          label="What type(s) of cancer?"
          personId={relativePersonId}
          stateManager={this.props.stateManager}
        ></CancerHistory>
      </React.Fragment>
    );

  } 

  renderAgeAndReasonRelativeEntry = (alreadyEnteredPersonIds, personFieldPath, stateProp, agePath, reasonPath, relativePersonId) => {
    const relativePersonIdWithCreate = _.partial(this.resolveRelativePersonIdWithCreate, relativePersonId);
    const isAlreadyEntered = _.includes(alreadyEnteredPersonIds, relativePersonId);

    return (
      <React.Fragment>
        {this.renderRelativeIdentity(relativePersonId, relativePersonIdWithCreate, isAlreadyEntered, personFieldPath, stateProp)}
        <FormGroup
          // label={ getLabel(localization, AGE_REMOVED) }
          label={ 'Age removed?' }
          className="ageRemoved"
        >
          <NumericInput
            disabled={false}
            onValueChange={(e) => {
              console.log({ value: e });
              this.props.stateManager.setPersonValue(relativePersonId, agePath, e)}}
            value={this.props.stateManager.getPersonValue(relativePersonId, agePath)}
          />
        </FormGroup>
        <FormGroup
          // label={ getLabel(localization, WHAT_WAS_THE_REASON) }
          label={ 'What was the reason?' }
          className="whatTheReason" 
        >
          <TextArea
            maxLength="255"
            onChange={(e) =>
              this.props.stateManager.setPersonValue(relativePersonId, reasonPath, e.target.value)}
            value={this.props.stateManager.getPersonValue(relativePersonId, reasonPath)}
          />
        </FormGroup>
      </React.Fragment>
    );
  }

  renderGenderRelativeEntry = (alreadyEnteredPersonIds, personFieldPath, relativePersonId) => {
    const relativePersonIdWithCreate = _.partial(this.resolveRelativePersonIdWithCreate, relativePersonId);
    const isAlreadyEntered = _.includes(alreadyEnteredPersonIds, relativePersonId);

    return (
      <React.Fragment>
        {this.renderRelativeIdentity(relativePersonId, relativePersonIdWithCreate, isAlreadyEntered, personFieldPath, 'relativePersonIdsDiffGenderIdentity')}
        <FormGroup
          label="Sex assigned at birth"
        >
          <RadioGroup
              onChange={(e) => {
                console.log({ e: e.target.value });
                this.props.stateManager.setPersonValue(relativePersonId, PersonFieldPaths.SEX, e.target.value)
              }}
              selectedValue={this.props.stateManager.getPersonValue(relativePersonId, PersonFieldPaths.SEX)}
          >
            <Radio label="Female" value="F" key={"F"} className='bp3-large' />
            <Radio label="Male" value="M" key={"M"} className='bp3-large' />
          </RadioGroup>
        </FormGroup>
        <FormGroup
          label="Gender Identity"
        >
          <RadioGroup
              onChange={(e) =>
                this.props.stateManager.setPersonValue(relativePersonId, PersonFieldPaths.GENDER_IDENTITY, e.target.value)}
              selectedValue={this.props.stateManager.getPersonValue(relativePersonId, PersonFieldPaths.GENDER_IDENTITY)}
          >
            <Radio label="Female" value="F" key={"F"} className='bp3-large' />
            <Radio label="Male" value="M" key={"M"} className='bp3-large' />
            <Radio label="Non-binary" value="N" key={"N"} className='bp3-large' />
            <Radio label="Genderqueer" value="G" key={"G"} className='bp3-large' />
            <Radio label="Other" value="O" key={"O"} className='bp3-large' />
          </RadioGroup>
        </FormGroup>
      </React.Fragment>
    );
  }

  renderRelativeEntrySeenElsewhere = (alreadyEnteredPersonIds, relativePersonId) => {
    const relativePersonIdWithCreate = _.partial(this.resolveRelativePersonIdWithCreate, relativePersonId);
    const isAlreadyEntered = _.includes(alreadyEnteredPersonIds, relativePersonId);

    return (
      <React.Fragment>
        {this.renderRelativeIdentity(relativePersonId, relativePersonIdWithCreate, isAlreadyEntered, PersonFieldPaths.HAS_RELATIVE_SEEN_ELSEWHERE_DESC, 'relativePersonIdsSeenElsewhere')}
        <FormGroup
          label={ getLabel(this.context.localization, PersonFieldPaths.HAS_RELATIVE_SEEN_ELSEWHERE_DESC, 'patient') }
        >
          <TextArea
            maxLength="255"
            onChange={(e) =>
              this.props.stateManager.setPersonValue(this.props.personId, PersonFieldPaths.HAS_RELATIVE_SEEN_ELSEWHERE_DESC, e.target.value)}
            value={this.props.stateManager.getPersonValue(this.props.personId, PersonFieldPaths.HAS_RELATIVE_SEEN_ELSEWHERE_DESC) || ''}
          />
        </FormGroup>
      </React.Fragment>
    );
  }

  renderRelativeIdentity = (relativePersonId, relativePersonIdWithCreate, isAlreadyEntered, personFieldPath, stateProp) => {
    return (
      <>
        <FormGroup
          label="Choose an already entered relative"
        >
          <PersonSuggest
            personIds={this.getPersonIdsOfRelatives(personFieldPath, false, false, true)}
            onSelect={_.partial(this.handlePersonSuggestChange(personFieldPath, stateProp), relativePersonId)}
            selectedPersonId={isAlreadyEntered ? relativePersonId : null}
            stateManager={this.props.stateManager}
          />
        </FormGroup>
        <p>Or, enter a new relative below:</p>
        <FullName
          id={relativePersonId}
          getValue={this.props.stateManager.getPersonValue.bind(this.props.stateManager)}
          setValue={this.props.stateManager.setPersonValue.bind(this.props.stateManager)}
          personIdWithCreate={relativePersonIdWithCreate}
          disabled = {isAlreadyEntered}
        />
        <DOBInput
          dobDateStr={this.props.stateManager.getPersonValue(relativePersonId, PersonFieldPaths.DATE_OF_BIRTH)}
          onChange={(date) => this.props.stateManager.setPersonValue(relativePersonIdWithCreate, PersonFieldPaths.DATE_OF_BIRTH, date && date.toISOString())}
          disabled={isAlreadyEntered}
        />
      </>
    );
  }

  resolveRelativePersonIdWithCreate = (relativePersonId, state) =>  {
    const famHelper = new FamilyHelper(state);

    if (!famHelper.doesPersonExist(relativePersonId)) {
      let newPerson = famHelper.addPerson("probandsRelative", undefined, relativePersonId);
      famHelper.addRelationship(relativePersonId, this.props.personId, RelationshipTypes.UNKNOWN);

      _.set(newPerson.getDetailsObj(), 'properties[0].type', 'hasBeenSeenByTeam');
      _.set(newPerson.getDetailsObj(), 'properties[0].isPresent', 'Y');
    }

    return relativePersonId;
  }

  getPersonIdsOfRelatives = (personFieldPath, isFieldPathTrue, haveKnownRelation, skipKnownRelationFilter = false) => {
    const famHelper = this.props.stateManager.getFamilyHelper();
    const allRelationships = famHelper.getAllRelationships();
    return _.chain(allRelationships)
      .filter((rel) => (_.get(rel, RelationshipPaths.TYPE) === RelationshipTypes.UNKNOWN) !== haveKnownRelation || skipKnownRelationFilter)
      .map((rel) => [_.get(rel, RelationshipPaths.SOURCE), _.get(rel, RelationshipPaths.TARGET)])
      .flatten()
      .uniq()
      .filter((personId) => personId !== this.props.stateManager.getProband().id)
      .filter((personId) => {
        const personDetailsObj = famHelper.getPerson(personId).getDetailsObj();
        const propertyObj = _.find(personDetailsObj.properties, { type: personFieldPath }) || {};

        return !_.isBoolean(isFieldPathTrue) 
          || (propertyObj.isPresent === 'Y') === isFieldPathTrue
      })
      .value();
  }

  handlePersonSuggestChange = (personFieldPath, stateProp) => (oldRelativePersonId, newRelativePersonId) => {
    if (this.props.stateManager.getPersonById(oldRelativePersonId)) {
      this.props.stateManager.setPersonValue(this.props.personId, PersonFieldPaths.PROPERTY_IS_PRESENT, undefined, {propertyIdx: { [getFieldName(PersonFieldPaths.PROPERTY_TYPE)]: personFieldPath }});
    }

    if (newRelativePersonId) { // If the suggest is being cleared, newRelativePersonId will be null
      this.props.stateManager.setPersonValue(newRelativePersonId, PersonFieldPaths.PROPERTY_IS_PRESENT, 'Y', {propertyIdx: { [getFieldName(PersonFieldPaths.PROPERTY_TYPE)]: personFieldPath }});
      this.setState((state) => {
        // Replace the old relative person ID with the new one
        this.lastAlreadyEnteredRelative = newRelativePersonId;
        state[stateProp] = _.map(state[stateProp], (personId) =>
          personId === oldRelativePersonId ? newRelativePersonId : personId);
      }, () => {
        this.lastAlreadyEnteredRelative = null;
      });
    } else {
      this.setState((state) => {
        // Replace the old relative person ID with a new UUID
        state[stateProp] = _.map(state[stateProp], (personId) =>
          personId === oldRelativePersonId ? uuidv4() : personId);
      });
    }
  }
}

export default RelativeLinkingStep;