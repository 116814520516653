import React, {useState} from 'react';
import { Button, Callout, Collapse, Card } from "@blueprintjs/core";

export default function PopUpCard({
  intent,
  title,
  message,
  buttons,
  errorMessage
}){
  const [isOpen, setIsOpen] = useState(false)
  return ( 
    <div className="infoCardContainer">
      <Card className={`infoCard intent-${intent}`}>
        <div className="cardTitle">
          <div className="d-flex align-items-center">
            <span><h4>{title}</h4></span>
          </div>
        </div>
        <div className="cardBody">
          <p>{message}</p>
          {
            errorMessage && Array.isArray(errorMessage.errors) && errorMessage.errors.map((error, i) => (
              <div className="errorToggle">
                <Button onClick={() => setIsOpen(!isOpen)}>{isOpen ? "Hide" : "Show"} error details</Button>
                <Collapse isOpen={isOpen}>
                  <Callout key={i} title={`${error.status} ${error.title}`}>
                    <div className="errorDetail">{error.detail}</div>
                  </Callout>
                </Collapse>
              </div>
            ))
          }
          <div className="buttonGroup">
            {buttons.map(button => {
              return <Button
                  key={button.id}
                  text={button.text}
                  intent={button.intent}
                  large="true"
                  onClick={button.action}
                  loading={button.loading}
                />
            })}
          </div>
        </div>
      </Card>
    </div>
  )}