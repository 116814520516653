import React, { useContext } from 'react';
import { FormGroup, Classes, NumericInput } from '@blueprintjs/core';
import Chooser from './Chooser.jsx';
import { PersonFieldPaths } from '../utils/QuestionnaireStateManager.js';
import { getLabel } from '../utils/helpers';
import Context from '../utils/context/Context'

const { CAUSE_OF_DEATH, AGE_OF_DEATH, POST_MORTEM_PERFORMED } = PersonFieldPaths


const DeathDetails = (props) => {
  const { localization } = useContext(Context);
  return(
    <React.Fragment>
      <FormGroup
        label={ getLabel(localization, CAUSE_OF_DEATH)}
      >
        <input
          className={Classes.INPUT}
          type="text"
          dir="auto"
          value={props.getPersonValue(CAUSE_OF_DEATH) || ''}
          onChange={(e) => props.setPersonValue(CAUSE_OF_DEATH, e.target.value)}
        />
      </FormGroup>
      <FormGroup
        label={ getLabel(localization, AGE_OF_DEATH) }
      >
        <NumericInput
          onButtonClick={(val) => props.setPersonValue(AGE_OF_DEATH, val)}
          onValueChange={(val) => props.setPersonValue(AGE_OF_DEATH, val)}
          value={props.getPersonValue(AGE_OF_DEATH) || ''}
          minorStepSize={null}
          min={0}
          max={120}
        />
      </FormGroup>
      <Chooser
        label={ getLabel(localization, POST_MORTEM_PERFORMED) }
        helperText={ getLabel(localization, POST_MORTEM_PERFORMED, null, 'subLabel') }
        onChange={(e) => props.setPersonValue(POST_MORTEM_PERFORMED, e.target.value)}
        selectedValue={props.getPersonValue(POST_MORTEM_PERFORMED)}
        orderSchema={['Y', 'N', 'U']}
        path={'yesNoUnknown'}
      />
    </React.Fragment>
  )};

export default DeathDetails;