import React, { useState } from 'react';
import { Switch } from '@blueprintjs/core';

function ConditionalSectionSwitch({ 
  children,
  conditionState,
  cleanUpChildQuestions,
  label, 
  onConditionStateChange,
  showWhenCondition, 
}) {
  const [condition, setCondition] = useState(conditionState);
  const CONDITIONS = { YES: 'Y', NO: 'N' };

  const renderChildren = () => {
    if (showWhenCondition === condition) {
      return children;
    } else {
      return null;
    }
  }  
  const onParentQuestionChange = (e) => {
    const value = e.currentTarget.checked ? CONDITIONS.YES : CONDITIONS.NO;
    setCondition(value);
    onConditionStateChange(value);
    if (value !== showWhenCondition) {
      cleanUpChildQuestions();
    }
  }
  return (
      <React.Fragment>
        <Switch
          checked={conditionState === CONDITIONS.YES}
          label={label}
          onChange={(e) => onParentQuestionChange(e)}
          innerLabelChecked="Yes"
          innerLabel="No"
        />
        {renderChildren()}
      </React.Fragment>
    );
  }

export default ConditionalSectionSwitch;