import React, { useContext } from 'react';
import { FormGroup } from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';

import { getLabel } from '../utils/helpers';
import Context from '../utils/context/Context';
import moment from 'moment';

const dateConstant = {
  format:"DD/MM/YYYY"
};

const DOBInput = ({dobDateStr, onChange, disabled}) => {
  const { localization } = useContext(Context);
  const dobDate = dobDateStr ? new Date(dobDateStr) : null;
  let minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 150);

  return (
    <FormGroup
      label={getLabel(localization, 'dateOfBirth')}
    >
      <DateInput
        formatDate={date => moment(date).format(dateConstant.format)}
        parseDate={str => moment(str,dateConstant.format).toDate()}
        placeholder={disabled ? '' : dateConstant.format}
        value={dobDate}
        fill={true}
        onChange={onChange}
        minDate={minDate}
        disabled={disabled}
      />
    </FormGroup>
  );
}

export default DOBInput;
