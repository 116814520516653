import React, { useState } from "react";
import { Select } from "@blueprintjs/select";
import { Button, MenuItem } from "@blueprintjs/core";

export default function DropdownSelector({
  defaultLabel,
  items,
  onItemSelect,
  noResultsOption
}) {
  const [selectedOption, setSelectedOption] = useState();

  const renderSelectItems = (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    const text = item.name;
    return (
      <MenuItem
        active={item.name === selectedOption}
        disabled={modifiers.disabled}
        key={item.id}
        onClick={handleClick}
        text={text}
      />
    );
  };

  const filterSelectItems = (query, type) => {
    return !query || type.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  };

  return (
    <Select
      items={items}
      itemPredicate={filterSelectItems}
      itemRenderer={renderSelectItems}
      noResults={noResultsOption ? <MenuItem text={noResultsOption.name} onClick={() => {
        onItemSelect(noResultsOption);
        setSelectedOption(noResultsOption.name);
      }}/> : <MenuItem disabled={true} text="No results." />}
      onItemSelect={(e) => {
        onItemSelect(e);
        setSelectedOption(e.name);
      }}
    >
      <Button
        text={selectedOption ? selectedOption : defaultLabel}
        rightIcon="caret-down"
        className="cancerType"
        fill={true}
      />
    </Select>
  );
}
