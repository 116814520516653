import React, { useContext } from 'react';
import { Button, Switch } from "@blueprintjs/core";
import Context from './utils/context/Context';

import saveToBackend, { finishSave, logout, resetForm, downloadPDF } from './utils/persistance'

function Controls({
  addToast,
  resetState,
  cptState,
  setState
}){
  const { loading, setLoading, demoMode, setDemoMode, setDialogBox, setLoggingIn , setAuthenticated } = useContext(Context);
  const saveThen = () => {
    saveToBackend(
      cptState,
      setState,
      setLoading,
      () => {},
      finishSave,
      loading,
      'save')
    .then(res => {
      console.log(res)
    })
    .catch(err => {
      console.log(err)
      setDialogBox({ show: true, type: "showSaveError", action: () => {}, errorMessage: err })
    })
  }
  return(
    <div className="save-buttons">
          <Button
            icon={"floppy-disk"}
            intent={"primary"}  
            onClick={saveThen}  
            minimal={true}
            disabled={loading.save}
            loading={loading.save}
            text={"Save"}
          />
      {/* TO DO - MERGE RESET BUTTONS WITH OTHER BUTTONS */}
      {
        demoMode &&
        <Button
          intent="danger"
          icon="reset"
          disabled={loading.reset}
          loading={loading.reset}
          minimal={true}
          onClick={() => {
            const newState = {
              ...cptState,
              ...resetState()
            }
            saveToBackend(
              newState, 
              setState, 
              setLoading, 
              addToast, 
              resetForm,
              loading,
              'reset'
            )
          }}
          text="Reset"
        />
      }
      { 
        demoMode && demoButtons.map(buttonInfo =>
          <Button
            key={buttonInfo.id}
            icon={buttonInfo.icon}
            intent={buttonInfo.intent}
            onClick={() => {
              saveToBackend(
                cptState, 
                setState, 
                setLoading, 
                addToast, 
                buttonInfo.callback,
                loading,
                buttonInfo.id,
                setLoggingIn,
                setAuthenticated
              )
            }
            }
            minimal={true}
            disabled={loading[buttonInfo.id]}
            loading={loading[buttonInfo.id]}
            text={buttonInfo.text}
          />
        )
      }
      {
        demoMode && 
          <Switch 
            checked={demoMode} 
            label="Demo Mode" 
            onChange={() => setDemoMode(false)} 
            className="demoModeSwitch"
          />
      }
    </div>)
}


const demoButtons = [
  {
    icon: "download",
    intent: "primary",
    callback: downloadPDF,
    text: "PDF",
    id: "generate"
  },
  {
    icon: "log-out",
    intent: "primary",
    callback: logout,
    text: "Logout",
    id: "logout"
  }
]


export default Controls;


