import React from 'react';
import { NonIdealState } from '@blueprintjs/core';
export default function ThankYou(){
  return <div>
    <NonIdealState
      icon='saved'
      title='Thank you for submitting!'
      description='Your Pre-visit questionnaire has been submitted.'
      className="thankYou"
    />
  </div>
}
