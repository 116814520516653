import _ from 'lodash';

/** Utils */
const isValueEmpty = (value) => {
  if (value === null || value === undefined || value === "") {
    return true;
  } else {
    return false;
  }
}

export const getSiblingStr = (sex) => {
  return {
    M: 'brother',
    F: 'sister'
  }[sex] || 'sibling';
}

export const getOffspringStr = (sex) => {
  return {
    M: 'son',
    F: 'daughter'
  }[sex] || 'child';
}

export const getParentStr = (side) => {
  return {
    paternal: 'father',
    maternal: 'mother',
  }[side];
}

export const getParentStrFromSex = (sex) => {
  return {
    F: 'mother',
    M: 'father'
  }[sex]
}

export const capitalize = (s) => {
    if (typeof s !== 'string') {
      return ''
    }
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export const getParentSex = (side) => {
  return {
    paternal: 'M',
    maternal: 'F'
  }[side];
}

export const getGrandparentStr = (sex) => {
  return {
    M: 'Grandfather',
    F: 'Grandmother'
  }[sex];
}


export const getErrorMessage = (err) => {
  const msg = _.get(err, 'response.body.message') || err.message || 'Unknown error';
  return _.truncate(msg, {
    length: 400,
    omission: ' [...]'
  });
}

export const getLabel = (fieldDefs, fieldPath, person, type = 'label') => {
  const labelPath = person ? `${person}.${fieldPath}.${type}` : `${fieldPath}.${type}`;
  return fieldDefs[`${labelPath}`]
}

export const getOptions = (fieldDefs, fieldPath, orderSchema, person, type = 'label') => {
  return orderSchema.map((value) => {
    const key = person ? `${person}.${fieldPath}.options.${value}.${type}` : `${fieldPath}.options.${value}.${type}`
    return [ value, fieldDefs[key] ]
  })
}


/**
 * Returns an display ready string based on pressence of first and last name information. It accounts for grammer in a situation where one or both of the names is unknown.
 * @param {string} firstName The value of the first name
 * @param {string} lastName The value of the last name
 * @returns a display ready field to print as a 'name'
 */
export const getFullName = (firstName, lastName) => {
  if (!firstName && !lastName){
    return 'Unknown Name'
  } else if (!firstName) {
    return `Unknown ${lastName}`
  } else if (!lastName) {
    return firstName;
  } else {
    return `${firstName} ${lastName}`
  }
}



export default isValueEmpty;