import React, { Component } from 'react';
import _ from 'lodash';

import Navigation from './Navigation.jsx';
import IntroductionStep from './steps/IntroductionStep.jsx';
import InstructionsStep from './steps/InstructionsStep.jsx';
import AncestryStep from './steps/AncestryStep.jsx';
import PersonStep from './steps/PersonStep.jsx';
import RelativeLinkingStep from './steps/RelativeLinkingStep.jsx';
import ConclusionStep from './steps/ConclusionStep.jsx';
import PersonSiblingsStep from './steps/PersonSiblingsStep.jsx';
import CousinInfoStep from './steps/CousinInfoStep.jsx';
import ChildInfoStep from './steps/ChildInfoStep.jsx';
import ExtendedFamilyInfoStep from './steps/ExtendedFamilyInfoStep.jsx';
import AdditionalInformation from './steps/AdditionalInformation.jsx'
import Controls from './Controls'

import { QuestionnaireStateManager, PersonFieldPaths } from './utils/QuestionnaireStateManager.js';
import FamilyHelper from './utils/FamilyHelper.js';

import { navigate } from '@reach/router'

import '../node_modules/@blueprintjs/core/lib/css/blueprint.css';

class Questionnaire extends Component {
  constructor(props) {
    super(props);
    this.stateManager = new QuestionnaireStateManager(this);
    this.state = QuestionnaireStateManager.getEmptyState();
  }
  render() {
    if (!(this.props.location.state && this.props.location.state.fromRedirect)) {
      navigate('/')
      return null
    } else { 
      const { id, addToast } = this.props;
      const { getProband, loadFromBackend } = this.stateManager;
      let probandResolver = (shouldCreate, state) => {
        return QuestionnaireStateManager.getProband(state)[PersonFieldPaths.ID];
      };
      return (

        <div id={id} className="clearfix">
          <Navigation
            cptState={this.stateManager.cpt.state}
            setState={this.stateManager.setState}
          >
            <IntroductionStep
              key="introduction"
              navId="introduction"
              navTitle={"Introduction"}
            />
            <InstructionsStep
              key="instructions"
              navId="instructions"
              navTitle={"Instructions"}
            />
            <AncestryStep
              key="ancestry"
              navId="ancestry"
              navTitle={"Ancestry"}
              icon="people"
              person={getProband()}
              stateManager={this.stateManager}
            />
            {this.renderFamilyStep(false, 'Your Information', 'proband', probandResolver, { type: 'proband' }, 'person')}
            {this.renderFamilySteps()}
            <RelativeLinkingStep
              personId={getProband().id}
              key="relative-linking"
              navId="relative-linking"
              icon="people"
              navTitle={"Other Questions"}
              stateManager={this.stateManager}
            />
            <AdditionalInformation
              key="additional-information"
              navId="additional-information"
              navTitle={"Additional Information"}
              stateManager={this.stateManager}
              cptState={this.stateManager.cpt.state}
            />
            <ConclusionStep
              key="conclusion"
              navId="conclusion"
              navTitle={"Conclusion"}
              person={getProband()}
            />
          </Navigation>
          <Controls
            key="controls"
            cptState={this.stateManager.cpt.state}
            setState={this.stateManager.setState}
            addToast={addToast}
            resetState={() => QuestionnaireStateManager.getEmptyState()}
            loadFromBackend={loadFromBackend}
          />
        </div>
      );
    }

  }

  componentDidMount() {
    this.stateManager.loadFromBackend();
  }

  renderFamilySteps() {
    let steps = [];
    let patientFamilySteps = [{
      id: 'patientsFamilyStep',
      label: "Patient's Family",
      type: 'folder'
    }];
    let mothersFamilyStep = [{
      id: 'mothersFamilyStep',
      label: "Mother's Family",
      type: 'folder'
    }];
    let fathersFamilyStep = [{
      id: 'fathersFamilyStep',
      label: "Father's Family",
      type: 'folder'
    }];

    let probandResolver = (shouldCreate, state) => {
      return QuestionnaireStateManager.getProband(state)[PersonFieldPaths.ID];
    };
    let motherResolver = (shouldCreate, state) => {
      const famHelper = new FamilyHelper(state);
      return famHelper.getPerson(QuestionnaireStateManager.getProband(state))
        .resolveParent('F', shouldCreate, "probandsMother").id;
    }
    let fatherResolver = (shouldCreate, state) => {
      const famHelper = new FamilyHelper(state);
      return famHelper.getPerson(QuestionnaireStateManager.getProband(state))
        .resolveParent('M', shouldCreate, "probandsFather").id;
    }
    
    const probandsChildren = ['M', 'F'].map((sex) => this.stateManager.getFamilyHelper().getChildren(probandResolver(false, this.state), sex))

    if (_.flatten(probandsChildren).length) {
      patientFamilySteps.push(this.renderPatientsChildrenStep());
    }
    
    patientFamilySteps.push(this.renderPersonSiblingCountsStep("Patient's siblings", 'proband', probandResolver));
    
    _.forEach(this.stateManager.getFamilyHelper().getSiblings(probandResolver(false, this.state)), (sibling) => {
      patientFamilySteps.push(this.renderPersonSiblingStep(sibling, probandResolver(false, this.state), "Patient", 'sibling'));
    });

    patientFamilySteps.push(this.renderPersonStep("Patient's mother", 'mother', 'F', motherResolver));
    
    patientFamilySteps.push(this.renderPersonStep("Patient's father", 'father', 'M', fatherResolver));

    mothersFamilyStep.push(this.renderPersonSiblingCountsStep("Mother's siblings", 'mother', motherResolver));

    _.forEach(this.stateManager.getFamilyHelper().getSiblings(motherResolver(false, this.state)), (sibling) => {
      mothersFamilyStep.push(this.renderPersonSiblingStep(sibling, motherResolver(false, this.state), "Mother", 'parentSibling'));
    });

    if (this.stateManager.getFamilyHelper().getFirstCousins(probandResolver(false, this.state), 'F').length){
      mothersFamilyStep.push(this.renderCousinStep("maternal"));
    }

    fathersFamilyStep.push(this.renderPersonSiblingCountsStep("Father's siblings", 'father', fatherResolver));

    _.forEach(this.stateManager.getFamilyHelper().getSiblings(fatherResolver(false, this.state)), (sibling) => {
      fathersFamilyStep.push(this.renderPersonSiblingStep(sibling, fatherResolver(false, this.state), "Father", 'parentSibling'));
    });

    if (this.stateManager.getFamilyHelper().getFirstCousins(probandResolver(false, this.state), 'M').length) {
      fathersFamilyStep.push(this.renderCousinStep("paternal"));
    }

    mothersFamilyStep.push(this.renderPersonStep("Patient's maternal grandmother", 'mat-gmother', 'F', (shouldCreate, state) => {
      const famHelper = new FamilyHelper(state);
      return famHelper.getPerson(QuestionnaireStateManager.getProband(state))
        .resolveParent('F', shouldCreate, "probandsMother")
        .resolveParent('F', shouldCreate, "probandsMaternalGrandmother").id;
    }));

    mothersFamilyStep.push(this.renderPersonStep("Patient's maternal grandfather", 'mat-gfather', 'M', (shouldCreate, state) => {
      const famHelper = new FamilyHelper(state);
      return famHelper.getPerson(QuestionnaireStateManager.getProband(state))
        .resolveParent('F', shouldCreate, "probandsMother")
        .resolveParent('M', shouldCreate, "probandsMaternalGrandfather").id;
    }));

    fathersFamilyStep.push(this.renderPersonStep("Patient's paternal grandmother", 'pat-gmother', 'F', (shouldCreate, state) => {
      const famHelper = new FamilyHelper(state);
      return famHelper.getPerson(QuestionnaireStateManager.getProband(state))
        .resolveParent('M', shouldCreate, "probandsFather")
        .resolveParent('F', shouldCreate, "probandsPaternalGrandmother").id;
    }));
    
    fathersFamilyStep.push(this.renderPersonStep("Patient's paternal grandfather", 'pat-gfather', 'M', (shouldCreate, state) => {
      const famHelper = new FamilyHelper(state);
      return famHelper.getPerson(QuestionnaireStateManager.getProband(state))
        .resolveParent('M', shouldCreate, "probandsFather")
        .resolveParent('M', shouldCreate, "probandsPaternalGrandfather").id;
    }));

    mothersFamilyStep.push(this.renderExtendedFamilyInfoStep('maternal'));
    fathersFamilyStep.push(this.renderExtendedFamilyInfoStep('paternal'));

    steps.push(patientFamilySteps);

    // if mother of father of the patient are adopted then not render their family steps
    const isMotherAdopted = this.stateManager.getPersonValue(motherResolver(false, this.state), PersonFieldPaths.ADOPTED_STATUS);
    const isFatherAdopted = this.stateManager.getPersonValue(fatherResolver(false, this.state), PersonFieldPaths.ADOPTED_STATUS);

    if (isMotherAdopted !== 'Y') {
      steps.push(mothersFamilyStep);
    } else {
      _.forEach(this.stateManager.getFamilyHelper().getSiblings(motherResolver(false, this.state)), (sibling) => {
        this.stateManager.removePerson(sibling.person.id);
      });
    }
    if (isFatherAdopted !== 'Y') {
      steps.push(fathersFamilyStep);
    } else {
      _.forEach(this.stateManager.getFamilyHelper().getSiblings(fatherResolver(false, this.state)), (sibling) => {
        this.stateManager.removePerson(sibling.person.id);
      });
    }

    return steps;
  }

  renderFamilyStep(isSiblingCountsStep, title, idLabel, resolvePersonId, customRenderProps, iconClassName, removePerson = false) {
    let id;
    if (isSiblingCountsStep) {
      id = 'person-' + idLabel + '-siblings';
    } else {
      id = 'person-' + idLabel
    }    
    let renderProps = {
      key: id,
      navId: id,
      navTitle: title,
      icon: iconClassName,
      stateManager: this.stateManager,
      targetPerson: idLabel,
      removePerson: removePerson
    };
    renderProps.resolvePersonId = resolvePersonId;
    _.assign(renderProps, customRenderProps);
    if (isSiblingCountsStep) {
      return <PersonSiblingsStep {...renderProps} />;
    } else {
      return <PersonStep {...renderProps} />;
    }
  }

  renderPersonStep(title, idLabel, sex, resolvePersonId, renderProps) {
    if (sex) {
      renderProps = renderProps || {};
      renderProps.sex = sex;
    }
    return this.renderFamilyStep(false, title, idLabel, resolvePersonId, renderProps, 'person');
  }

  renderPersonSiblingCountsStep(title, idLabel, resolvePersonId, relationshipPrefix) {
    return this.renderFamilyStep(true, title, idLabel, resolvePersonId, null, 'people', relationshipPrefix);
  }

  renderPersonSiblingStep(siblingObj, siblingOfId, siblingOfLabel, type) {
    const removeSibling = () => {
      return this.stateManager.removePerson(siblingObj.person.id);
    }
    return this.renderFamilyStep(
      false,
      Questionnaire.getSiblingNavTitle(siblingOfLabel, siblingObj),
      siblingObj.person.id,
      () => siblingObj.person.id,
      {
        type: type,
        siblingPersonId: siblingOfId,
        isHalfSibling: FamilyHelper.isHalfSibling(siblingObj),
        sex: _.get(siblingObj.person.getDetailsObj(), PersonFieldPaths.SEX),
        siblingOfLabel: siblingOfLabel
      },
      'person',
      removeSibling
    );
  }

  renderPatientsChildrenStep(){
    const key = "probands-children"
    return (
      <ChildInfoStep
        person={this.stateManager.getProband()}
        key={key}
        navId={key}
        icon='people'
        navTitle={"Patient's children"}
        stateManager={this.stateManager}
      />
    );
  }

  renderCousinStep(side) {
    const key = side + "-cousins";
    const sideLabel = {
      paternal: "Paternal",
      maternal: "Maternal"
    }[side];

    return (
      <CousinInfoStep
        side={side}
        person={this.stateManager.getProband()}
        key={key}
        navId={key}
        icon='people'
        navTitle={sideLabel + " cousins"}
        stateManager={this.stateManager}
      />
    );
  }

  renderExtendedFamilyInfoStep(side) {
    const key = side + 'ext-family';
    const sideLabel = {
      paternal: "Paternal",
      maternal: "Maternal"
    }[side];

    return (<ExtendedFamilyInfoStep
      side={side}
      personId={this.stateManager.getProband().id}
      navId={key}
      key={key}
      icon="people"
      navTitle={sideLabel + " extended family"}

      stateManager={this.stateManager}
    />);
  }
}

Questionnaire.getSiblingNavTitle = (subjectTitle, sibling) => {
  const isHalfSibling = FamilyHelper.isHalfSibling(sibling);
  const personDetails = sibling.person.getDetailsObj();
  const siblingSexStr = {
    M: 'brother',
    F: 'sister'
  }[_.get(personDetails, PersonFieldPaths.SEX)];
  let returnStr = `${subjectTitle}'s ${isHalfSibling ? 'half-' : ''}${siblingSexStr}`;
  let fname = _.get(personDetails, PersonFieldPaths.FIRST_NAME);
  let lname = _.get(personDetails, PersonFieldPaths.LAST_NAME);
  if (fname || lname) {
    returnStr += ` ${String.fromCodePoint(8226)} ${fname || ''}${lname ? (' ' + lname) : ''}`;
  }
  return returnStr;
}

export default Questionnaire;