import request from 'superagent';
import { navigate } from "@reach/router"

export const getAccessToken = () => {
  return window.location.search;
}

const saveToBackend = (
  state,
  setState,
  isLoading,
  setToasts,
  onSuccessfulSave,
  loadingState,
  loadingType,
  setLoggingIn,
  setAuthenticated
) => {
  return new Promise((resolve, reject) => {
    isLoading({ ...loadingState, [loadingType]: true });
    request
      .get("/api/v1/questionnaire/current-session")
      .accept("json")
      .then((resp1) => {
        resp1.body.pedigree = state;
        request
          .put("/api/v1/questionnaire/current-session")
          .accept("json")
          .send(resp1.body)
          .then((res) => {
            setState(res.body.pedigree);
            onSuccessfulSave(
              isLoading,
              setToasts,
              loadingState,
              loadingType,
              setLoggingIn,
              setAuthenticated
            )
              .then((res) => {
                console.log("Secondary Action completed");
                resolve(res);
              })
              .catch((err) => {
                console.log("Secondary Action failed");
                reject(err);
              });
          })
          .catch((err) => {
            if (
              err.response &&
              err.response.body &&
              err.response.body.status === 401
            ) {
              navigate("/", { replace: true });
            }
            console.log("Saved failed", err);
            clearLoading(isLoading, loadingState, loadingType);
            reject(err.response && err.response.body ? err.response.body : err);
          });
      })
      .catch(() => {
        //TODO: how to keep the 404 error from appearing in the console.
      });
  });
};

export const submitData = (isLoading, setToasts, loadingState, loadingType) => {
  return new Promise((resolve, reject) => {
    request
      .get(`/api/v1/questionnaire/current-session/submit${getAccessToken()}`)
      .accept('json')
      .then(res => {
        console.log("Submit response:", res)
        clearLoading(isLoading, loadingState, loadingType)
        setToasts('success', 'primary', "Successfully Submitted")
        resolve("Submit Response:" + res)
      })
      .catch(err => {
        console.warn("Submit Error: ", err)
        clearLoading(isLoading, loadingState, loadingType)
        setToasts('error', 'danger', err.message)
        reject("Submit Error:" + err)
      });
  })
}

export const logout = (isLoading, setToasts, loadingState, loadingType, setLoggingIn, setAuthenticated) => {
  return new Promise((resolve, reject) => {
    callLogout(((res) => {
      console.log("Log Out response:", res)
      clearLoading(isLoading, loadingState, loadingType)
      setToasts('success', 'primary', "Successfully Logged Out")
      setLoggingIn(false)
      setAuthenticated(false)
      navigate('/', { replace: true })
      resolve("Log Out Response:" + res)
    }),
      ((err) => {
        console.log(err)
        console.warn("Log Out Error: ", err)
        clearLoading(isLoading, loadingState, loadingType)
        setToasts('error', 'danger', err.message)
        reject("Log Out Error:" + err)
      }));
  })
}
export const callLogout = (success, failure) => {
  request
    .post(`/logout${getAccessToken()}`)
    .then(res => success(res))
    .catch(err => {
      failure(err)
    });
}
export const login = (success, failure) => {
  request
    .get(`/api/v1/login/${getAccessToken()}`)
    .then(res => {
      success()
    })

    .catch(err => {
      console.log("Log In error:", err)
      failure(err)
    });
}

export const finishSave = (isLoading, setToasts, loadingState, loadingType) => {
  return new Promise((resolve, reject) => {
    clearLoading(isLoading, loadingState, loadingType)
    setToasts('save', 'success', "Form Responses Saved")
    resolve('save success - Form Responses Saved')
  })
}

export const resetForm = (isLoading, setToasts, loadingState, loadingType) => {
  return new Promise((resolve, reject) => {
    clearLoading(isLoading, loadingState, loadingType)
    setToasts('reset', 'success', "Form Responses Reset. You must manually remove patients from the PhenoTips dashboard.")
    resolve('reset successful')
  })
}

const clearLoading = (isLoading, loadingState, loadingType) => {
  isLoading({ ...loadingState, [loadingType]: false })
}

export const downloadPDF = (isLoading, setToasts, loadingState, loadingType) => {
  return new Promise((resolve, reject) => {
    request.get(`/api/v1/questionnaire/current-session/export/pdf${getAccessToken()}`)
      .accept('application/octet-stream')
      .set('Authorization', 'Basic QWRtaW46YWRtaW4=')
      .type('application/json')
      .responseType('arraybuffer')
      .responseType('blob')
      .then(res => {
        const file = new Blob([res.body], { type: 'application/pdf' });
        const fileName = res.header['content-disposition'].split('"')[1];
        // For MS Edge and IE 
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(file, fileName);
        } else {
          // For other browsers: create a link pointing to the ObjectURL containing the blob.
          const objUrl = URL.createObjectURL(file);
          let link = document.createElement('a');
          link.href = objUrl;
          link.download = res.header['content-disposition'].split('"')[1];
          link.click();

          // For Firefox it is necessary to delay revoking the ObjectURL.
          setTimeout(() => { URL.revokeObjectURL(objUrl); }, 100);
        }
        clearLoading(isLoading, loadingState, loadingType)
        resolve(res.body)
      })
      .catch(err => {
        console.warn("Download Error: ", err)
        clearLoading(isLoading, loadingState, loadingType)
        setToasts('error', 'danger', err.message)
        reject(err)
      });
  })
}

export default saveToBackend;
