import React, { Component } from 'react';
import { Callout } from '@blueprintjs/core';

class ConclusionStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  render() {
    return (
      <div className="bodyText">
        <p>Thank you for taking the time to fill out this survey.  The information you have provided will be very useful for our team.  One of our nurse specialists will call to discuss the family history, the upcoming visit, and the patient’s medical history. When your family comes for your visit, we will also talk about the family history in more detail.</p>
        <Callout intent="primary">
          <p className="text-size-sm">For family members who have passed away from heart conditions, it is very useful for us to be able to see post mortem reports if they exist.  For any relatives you have listed with causes of death related for the heart, we would be very appreciative if you are able to obtain and share these reports before or on the day of the visit, if you are able.</p>
        </Callout>

        {/* TODO - Move the following information to the final page and some sort of info bar */}
        {/* <p>If you have any questions about the information in this survey or wish to speak with us further, please email us at icvd@gosh.nhs.uk or over the phone at 020 7405 9200 ext. 5124, 5305, and 5139.</p> */}
      </div>
    );
  }
}

export default ConclusionStep;