import React, { useState } from 'react';
import { Checkbox, FormGroup } from '@blueprintjs/core';
import _ from 'lodash';

export const CONDITIONS = {
  ALL: 'all',
  ANY: 'any',
  COUNT: 'count',
  SPECIFIC: 'specific'
};

function MultipleConditionalSection({ 
  children,
  label,
  countCondition,
  specificConditionValues,
  showWhenCondition,
  options,
  onChange
}) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  
  const renderChildren = () => {
    let showChildren = false;
    switch (showWhenCondition) {
      case CONDITIONS.ALL:
        showChildren = options.length === selectedOptions.length;
        break;
      case CONDITIONS.ANY:
        showChildren = selectedOptions.length > 0;
        break;
      case CONDITIONS.COUNT:
          showChildren = selectedOptions.length === countCondition;
          break;
      case CONDITIONS.SPECIFIC:
        showChildren = _.every(specificConditionValues, value => _.includes(_.map(selectedOptions, 'id'), value))
          && specificConditionValues.length === selectedOptions.length;
        break;
      default:
        return null;
    }
    if (showChildren) {
      return children;
    }
    return null;
  }  

  const renderOptions = () => {
    if (_.isArray(options)) {
        return options.map(option => (
            <Checkbox key={option.id} large={true} onChange={handleOptionChange(option)}>
                {option.name}
            </Checkbox>
        ));
    }
    return null;
  }
  const handleOptionChange = option => () => {
    let isOptionAlreadySelected = _.some(selectedOptions, { id: option.id });
    let updatedSelectedOptions = [...selectedOptions];
    if (isOptionAlreadySelected) {
      updatedSelectedOptions = _.filter(updatedSelectedOptions, ({ id }) => id !== option.id);
    } else {
      updatedSelectedOptions.push(option);
    }
    setSelectedOptions(updatedSelectedOptions);
    onChange(updatedSelectedOptions);
  }
  return (
    <React.Fragment>
      <FormGroup
        label={label}
      >
        {renderOptions()}
      </FormGroup>
      {renderChildren()}
    </React.Fragment>
  );
}

export default MultipleConditionalSection;
