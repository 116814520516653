import React, { useContext } from 'react';
import ConditionalSection from './ConditionalSection'
import { FormGroup, TextArea } from '@blueprintjs/core';

import { PersonFieldPaths as paths, getFieldName  } from '../utils/QuestionnaireStateManager.js';
import { getLabel } from '../utils/helpers';
import Context from '../utils/context/Context'


export default function HeartProblems({ personId, stateManager, personIdWithCreate, helperText, personDesc}) {
  const { localization } = useContext(Context);
  const CONDITION_NAME = "heart";
  const person = personDesc ? personDesc : 'person'
  return (
    <ConditionalSection
      label={getLabel(localization, `conditions.${CONDITION_NAME}.isPresent`, person)}
      showWhenCondition='Y'
      conditionState={stateManager.getPersonValue(
        personId,
        paths.CONDITION_IS_PRESENT,
        { conditionIdx: { [getFieldName(paths.CONDITION_CONDITION_NAME)]: CONDITION_NAME } }
      )}
      onConditionStateChange={(e) => {
        stateManager.setPersonValue(
          personIdWithCreate,
          paths.CONDITION_IS_PRESENT,
          e.target.value,
          { conditionIdx: { [getFieldName(paths.CONDITION_CONDITION_NAME)]: CONDITION_NAME } }
        )
      }}
      orderSchema={['Y', 'N', 'U']}
      cleanUpChildQuestions={() => {
        stateManager.setPersonValue(
          personIdWithCreate,
          paths.CONDITION_DESCRIPTION,
          null,
          { conditionIdx: { [getFieldName(paths.CONDITION_CONDITION_NAME)]: CONDITION_NAME } }
        )
      }}
    >
      <FormGroup
        label={getLabel(localization, `conditions.${CONDITION_NAME}.description`, person)}
        helperText={helperText || ''}
      >
        <TextArea
          maxLength="20000"
          onChange={(e) =>
            stateManager.setPersonValue(
              personIdWithCreate,
              paths.CONDITION_DESCRIPTION,
              e.target.value,
              { conditionIdx: { [getFieldName(paths.CONDITION_CONDITION_NAME)]: CONDITION_NAME } }
            )
          }
          value={stateManager.getPersonValue(
            personId,
            paths.CONDITION_DESCRIPTION,
            { conditionIdx: { [getFieldName(paths.CONDITION_CONDITION_NAME)]: CONDITION_NAME } }
          ) || ''}
        />
      </FormGroup>
    </ConditionalSection>
  );
}