import React, { Component } from 'react';
import _ from 'lodash';
import { FormGroup, InputGroup, TextArea, Icon } from '@blueprintjs/core';
import { FamilyHelper, RelationshipPaths, RelationshipTypes, translateRelationshipArray } from "../utils/FamilyHelper.js";
import { getParentStr, getParentSex, getLabel } from '../utils/helpers.js'

import MultiPersonEntry from '../widgets/MultiPersonEntry';
import PersonSuggest from '../widgets/PersonSuggest';
import FullName from '../widgets/FullName'
import { PersonFieldPaths, getFieldName } from '../utils/QuestionnaireStateManager.js';
import Context from '../utils/context/Context'

class ExtendedFamilyInfoStep extends Component {
  static contextType = Context
  render() {
    return (
      <React.Fragment>
        <div className="mainLabel">
          <p>Please add and give details (using the  <Icon icon="edit" intent="warning" iconSize='16' />  icon) for all relatives on the {getParentStr(this.props.side)}'s side of the family who have experienced any heart problems.</p>
        </div>
        
        <MultiPersonEntry
          personIds={this.getRelativePersonIds()}
          entryRenderer={this.renderRelativeEntry.bind(this)}
          personLabel={() => `${this.props.side} Relative`}
          stateManager={this.props.stateManager}
          getValue={this.props.stateManager.getPersonValue.bind(this.props.stateManager)}
          addAndRemoveEntries={true}
        />
      </React.Fragment>
    );
  }

  renderRelativeEntry = (relativePersonId) => {
    const relativePersonIdWithCreate = _.partial(this.resolveRelativePersonIdWithCreate, relativePersonId);
    const resolveRelativeRelationshipWithCreate = (state) => {
      const relativePersonId = relativePersonIdWithCreate(state);
      return (new FamilyHelper(state)).getUserDefinedRelativeRelationship(this.props.personId, relativePersonId);
    }
    const relativeRel = this.props.stateManager.getFamilyHelper()
      .getUserDefinedRelativeRelationship(this.props.personId, relativePersonId);
    let selectedGrandparentPersonId = this.getRelatedGrandparentPersonId(relativePersonId);

    return (
      <React.Fragment>
        <FullName
          id={relativePersonId}
          getValue={this.props.stateManager.getPersonValue.bind(this.props.stateManager)}
          setValue={this.props.stateManager.setPersonValue.bind(this.props.stateManager)}
          personIdWithCreate={relativePersonIdWithCreate}
        />
        <FormGroup label={getLabel(this.context.localization, 'properties.userDefinedRelationship' )} >
          <InputGroup
            value={(relativeRel && _.get(relativeRel, RelationshipPaths.PROPERTIES_USER_DEFINITION)) || ''}
            onChange={(e) => {
                this.props.stateManager.setRelationshipProperty(resolveRelativeRelationshipWithCreate, RelationshipPaths.PROPERTIES_USER_DEFINITION, e.target.value);

                this.props.stateManager.setPersonValue(
                  relativePersonIdWithCreate,
                  PersonFieldPaths.PROPERTY_VALUE,
                  e.target.value,
                  {propertyIdx: { [getFieldName(PersonFieldPaths.PROPERTY_TYPE)]: 'userDefinedRelationship' }})
              }
            }
          />
        </FormGroup>
        <PersonSuggest
          labelText={"Related grandparent (if known)"}
          personIds={this.getGrandparentsPersonIds()}
          onSelect={_.partial(this.handlePersonSuggestChange, relativePersonId, selectedGrandparentPersonId)}
          selectedPersonId={selectedGrandparentPersonId || null}
          stateManager={this.props.stateManager}
        />
        <FormGroup label={ getLabel(this.context.localization, 'conditions.heart.description', 'extendedFamily') }
          >
          <TextArea
            maxLength="20000"
            onChange={(e) =>
              this.props.stateManager.setPersonValue(
                relativePersonIdWithCreate,
                PersonFieldPaths.CONDITION_DESCRIPTION,
                e.target.value,
                { conditionIdx: { [getFieldName(PersonFieldPaths.CONDITION_CONDITION_NAME)]: 'heart' } }
              )
            }
            value={this.props.stateManager.getPersonValue(
              relativePersonId,
              PersonFieldPaths.CONDITION_DESCRIPTION,
              { conditionIdx: { [getFieldName(PersonFieldPaths.CONDITION_CONDITION_NAME)]: 'heart' } }
            ) || ''}
          />
        </FormGroup>
      </React.Fragment>
    );
  }

  handlePersonSuggestChange = (relativePersonId, oldGrandparentPersonId, newGrandparentPersonId) => {
    this.props.stateManager.modifyState((draftState) => {
      const famHelper = new FamilyHelper(draftState);

      if (oldGrandparentPersonId) {
        famHelper.removeRelationship(relativePersonId, oldGrandparentPersonId, RelationshipTypes.UNKNOWN);
      }
      if (newGrandparentPersonId) {
        this.resolveRelativePersonIdWithCreate(relativePersonId, draftState);
        famHelper.addRelationship(relativePersonId, newGrandparentPersonId, RelationshipTypes.UNKNOWN);
      }
    });
  }

  resolveRelativePersonIdWithCreate = (relativePersonId, state) => {
    const famHelper = new FamilyHelper(state);

    if (!famHelper.doesPersonExist(relativePersonId)) {
      famHelper.addUserDefinedRelative(this.props.personId, relativePersonId, ['proband', getParentStr(this.props.side)]);
      // Create an "unknown" relationship to the appropriate parent, creating the parent Person if necessary
      famHelper.addRelationship(relativePersonId, this.resolveParent(true, state).id, RelationshipTypes.UNKNOWN);
      // Add the heart condition details
      const person = famHelper.getPerson(relativePersonId);
      const resolvedPath = this.props.stateManager.resolvePath(
        person.getDetailsObj(),
        PersonFieldPaths.CONDITION_IS_PRESENT,
        ExtendedFamilyInfoStep.heartProblemsSearchCondition,
        true
      );
      _.set(person.getDetailsObj(), resolvedPath, 'Y');
    }

    return relativePersonId;
  }

  getRelativePersonIds = () => {
    const parentId = this.resolveParent(false, this.props.stateManager.getState()).id;
    const userDefinedRelativesPersonIds =
      _.keys(this.props.stateManager.getFamilyHelper().getUserDefinedRelatives(this.props.personId));
    return _.chain(this.props.stateManager.getFamilyHelper().getAllRelationships())
      // relatives (with unknown relation) of the appropriate parent
      .filter((rel) => _.get(rel, RelationshipPaths.TARGET) === parentId
        && _.get(rel, RelationshipPaths.TYPE) === RelationshipTypes.UNKNOWN)
      .map(RelationshipPaths.SOURCE)
      // have a heart problem
      .filter((personId) => this.props.stateManager.getPersonValue(personId, PersonFieldPaths.CONDITION_IS_PRESENT,
          ExtendedFamilyInfoStep.heartProblemsSearchCondition) === 'Y')
      // user defined relatives of the proband
      .intersection(userDefinedRelativesPersonIds)
      .value();
  }

  getRelatedGrandparentPersonId = (personId) => {
    return _.chain(this.props.stateManager.getFamilyHelper().getAllRelationships())
      .filter((rel) => _.get(rel, RelationshipPaths.SOURCE) === personId
        && _.get(rel, RelationshipPaths.TYPE) === RelationshipTypes.UNKNOWN)
      .map(RelationshipPaths.TARGET)
      .intersection(this.getGrandparentsPersonIds())
      .head()
      .value();
  }

  getGrandparentsPersonIds = () => {
    const parent = this.resolveParent(false, this.props.stateManager.getState());
    return parent
      ? _.compact([parent.resolveParent('M', false).id, parent.resolveParent('F', false).id])
      : [];
  }

  resolveParent(shouldCreate, state) {
    return (new FamilyHelper(state)).getPerson(this.props.stateManager.getProband().id)
      .resolveParent(getParentSex(this.props.side), shouldCreate, translateRelationshipArray(['proband'], getParentStr(this.props.side)));
  }

  getRelatedParentOrGrandparent() {
    return this.props.stateManager.getFamilyHelper().getUserDefinedRelatives();
  }
}

ExtendedFamilyInfoStep.heartProblemsSearchCondition = {
  conditionIdx: { [getFieldName(PersonFieldPaths.CONDITION_CONDITION_NAME)]: 'heart' }
};

export default ExtendedFamilyInfoStep;