import React from 'react'
import { Popover } from '@blueprintjs/core';

export default function ConditionIndicators({medicalConditions}){
  return (
    <div className="medicalConditions d-flex align-items-center">
      {
        medicalConditions.map((medicalCondition, i) => medicalCondition.status && (
          <Popover key={medicalCondition.id}>
            <span className={`medicalCondition-${i} medicalCondition`}></span>
            <div className="medicalConditionDescription">{medicalCondition.description}</div>
          </Popover>
          )
        )
      }
    </div>
  )
}