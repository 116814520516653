import React, { Component } from 'react';
import { Button } from '@blueprintjs/core';
import _ from 'lodash';
import uuidv4 from 'uuid/v4';
import { getFullName } from '../utils/helpers'
import InternalCard from './InternalCard'
import { PersonFieldPaths } from '../utils/QuestionnaireStateManager.js';

const { FIRST_NAME, LAST_NAME } = PersonFieldPaths

class MultiPersonEntry extends Component {
  constructor(props) {
    super(props);

    this.state = {
      personEntryIds: this.props.personIds && this.props.personIds.length
        ? _.clone(this.props.personIds)
        : []
    };
  }

  render() {
    const personIds = this.isControlledComponent() ? this.props.personIds : this.state.personEntryIds;
    return (
      <React.Fragment>
        {personIds.map(personId => 
          <InternalCard
            key={personId}
            opened={this.props.focusedPersonId === personId}
            fullName={getFullName(this.props.getValue(personId, FIRST_NAME), this.props.getValue(personId, LAST_NAME))}
            relationship={this.props.personLabel(personId)}
            removePersonEntry={_.partial(this.removePersonEntry, personId) }
            medicalConditions={this.getMedicalConditions(personId)}
          >
            {this.props.entryRenderer(personId)}
          </InternalCard>
        )}
        {this.props.addAndRemoveEntries && (
          <Button
            icon="add"
            text="Add Relative"
            minimal={true}
            intent="primary"
            className="addButton"
            onClick={this.addPersonEntry}
          />
        )}
      </React.Fragment>
    );
  }

  removePersonEntry = (personId) => {
    if (this.isControlledComponent()) {
      return this.props.onRemovePersonEntry(personId);
    }

    this.setState((state) => {
      state.personEntryIds = _.without(state.personEntryIds, personId);
      return state;
    })
    this.props.stateManager.removePerson(personId);
  }

  addPersonEntry = () => {
    if (this.isControlledComponent()) {
      return this.props.onAddPersonEntry();
    }

    this.setState((state) => {
      state.personEntryIds = [...state.personEntryIds, uuidv4()];
      return state;
    });
  }

  isControlledComponent = () => {
    return _.isFunction(this.props.onAddPersonEntry) && _.isFunction(this.props.onRemovePersonEntry);
  }
  getMedicalConditions = (cousinId) => {
    const lifestatus = this.props.stateManager.getPersonValue(cousinId, PersonFieldPaths.ALIVE_STATUS);
    const heart = this.props.stateManager.getPersonValue(cousinId, PersonFieldPaths.CONDITION_IS_PRESENT, { conditionName: 'heart' })
    const medicalConditions = [
      { status: (heart === 'Y'), id: 'heart', description: 'Person has Heart Condition' },
      { status: (lifestatus === 'N'), id: 'deceased', description: 'Person is deceased' },
    ]
    return medicalConditions
  }
}

export default MultiPersonEntry;