import React from 'react';
import { Button, Icon} from "@blueprintjs/core";


export default function CurrentStep({
  icon,
  navTitle,
  currentStep,
  loading,
  handleMovePrevious,
  canMovePrevious,
  isLastStep,
  saveAndMoveToNext,
  setSubmitWarning
}) {
  return (
    <div key="curr" id="questionnaire-current-step" className="bp3-card bp3-elevation-0">
    <div className='cardTitle'>
      <div className="d-flex align-items-center">
        <Icon icon={icon} color='#082B45' iconSize='14' className="cardTitleIcon" />
        <span><h4>{navTitle}</h4></span>
      </div>
    </div>
    <div className='cardBody'>
      {currentStep}
      <nav key="next-prev" id="questionnaire-nav-prev-next">
        <Button
          text="Previous Step"
          className="btn-prev"
          intent="primary"
          outlined={true}
          large="true"
          onClick={handleMovePrevious}
          disabled={!canMovePrevious() || loading.save}
          icon={"arrow-left"}
        />
        {!isLastStep() ?
          <Button
            text="Save & Continue"
            intent="primary"
            large="true"
            className="btn-next"
            onClick={() => { saveAndMoveToNext() }}
            disabled={isLastStep() || loading.save}
            rightIcon={"arrow-right"}
            loading={loading.save}

          />
          :
          <Button
            text="Submit Questionnaire"
            intent="danger"
            large="true"
            className="btn-next"
            onClick={() => setSubmitWarning(true) }
            disabled={!isLastStep()}
            rightIcon={"arrow-right"}
          />
        }
      </nav>
    </div>
  </div>
  )}