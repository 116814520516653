import { navigate } from "@reach/router";
import React, { useRef, useState, useContext } from "react";
import IdleTimer from "react-idle-timer";
import PopUpCard from './widgets/PopUpCard';
import { login, callLogout } from './utils/persistance.js';
import Context from './utils/context/Context';

let countdownInterval;
let timeout;

const timeoutConstant = {
    SESSION_TIMEOUT: (1000 * 60 * 20),
    WARNING_DURATION: 120
};

const SessionTimeout = () => {
    const { isAuthenticated, setAuthenticated, setLoggingIn } = useContext(Context);
    const [timeoutModal, setTimeoutModal] = useState({
        type: "",
        open: false
    });
    const [timeoutCountdown, setTimeoutCountdown] = useState(0);
    const idleTimer = useRef(null);
    const clearSessionTimeout = () => {
        clearTimeout(timeout);
    };
    const clearSessionInterval = () => {
        clearInterval(countdownInterval);
    };
    const handleLogout = async (isTimedOut = false) => {
        try {
            setTimeoutModal({
                open: true,
                type: "timedOut"
            });
            clearSessionInterval();
            clearSessionTimeout();
            setAuthenticated(false)
            setLoggingIn(false)
            callLogout(() => {
            // This is intentional
            },
            () => {
            // This is intentional
            });

        } catch (err) {
            console.error(err);
        }
    };
    const handleContinue = () => {
        login(() => {
            setTimeoutModal({
                open: false
            });
            clearSessionInterval();
            clearSessionTimeout();
        }, (err) => {
            handleLogout(true)
        })
    };
    const onActive = () => {
        if (!timeoutModal.open) {
            clearSessionInterval();
            clearSessionTimeout();
        }
    };
    const onIdle = () => {
        const delay = 1000 * 1;
        if (isAuthenticated && !timeoutModal.open) {
            timeout = setTimeout(() => {
                let countDown = timeoutConstant.WARNING_DURATION;
                setTimeoutModal({
                    type: "warning",
                    open: true
                });
                setTimeoutCountdown(countDown);
                countdownInterval = setInterval(() => {
                    if (countDown > 0) {
                        setTimeoutCountdown(--countDown);
                    } else {
                        handleLogout(true);
                    }
                }, 1000);
            }, delay);
        }
    };
    return (
        <>
            <IdleTimer
                ref={idleTimer}
                onActive={onActive}
                onIdle={onIdle}
                debounce={250}
                timeout={timeoutConstant.SESSION_TIMEOUT}
            />
            {timeoutModal.open && timeoutModal.type === 'warning' &&
                <PopUpCard
                    title={"Session expiring"}
                    intent={"warning"}
                    message={"Your session will expire soon due to inactivity"}
                    buttons={[
                        {
                            id: "resumeButton",
                            text: "Resume " + timeoutCountdown,
                            intent: "none",
                            action: () => handleContinue()
                        },
                    ]}
                />
            }
            {timeoutModal.open && timeoutModal.type === 'timedOut' &&
                <PopUpCard
                    title={"Session expired"}
                    intent={"danger"}
                    message={"This session has timed out due to inactivity. To resume, please click or copy-paste the link you received from your healthcare provider again"}
                    buttons={[
                        {
                            id: "sessionExpiryOkButton",
                            text: "Ok",
                            intent: "none",
                            action: (() => {
                                setTimeoutModal({ open: false })
                                navigate('/');
                            })
                        },
                    ]}
                />
            }
        </>
    );
}
export default SessionTimeout;
