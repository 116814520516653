import React, { Component } from 'react';
import _ from 'lodash';

import { NumericInput, FormGroup } from '@blueprintjs/core';

class PersonSiblingsStep extends Component {
  constructor(props) {
    super(props);

    this.resolvePersonIdWithCreate = _.partial(this.props.resolvePersonId, true);

    this.state = {
    }

    _.bindAll(this, [
      'handleCountChange'
    ]);
  }

  render() {
    return (
      <div>
        <div className='mainLabel'>
          <p>How many siblings does this person have?</p>
        </div>
        <div className='siblingGrid'>
          {this.renderSiblingCountInput('M', 'full')}
          {this.renderSiblingCountInput('M', 'half')}
          {this.renderSiblingCountInput('F', 'full')}
          {this.renderSiblingCountInput('F', 'half')}
        </div>
      </div>
    )
  }

  renderSiblingCountInput(siblingSex, fullOrHalf) {
    const siblingSexStr = {
      M: 'brother',
      F: 'sister'
    }[siblingSex];
    const label = <div className="siblingLabel">
                    <p className='subLabel'>{`${fullOrHalf === 'half' ? 'half-' : ''}${siblingSexStr}s`}</p> 
                    {fullOrHalf === 'full' && 
                    <p className="explainerText">{`(excluding half-${siblingSexStr}s)`}</p>}
                  </div>

    const personId = this.props.resolvePersonId(false, this.props.stateManager.cpt.state);
    const changeHandler = _.partial(this.handleCountChange, siblingSex, fullOrHalf, this.props.targetPerson);

    return (
      <FormGroup
        label={label}
        inline={true}
      >
        <NumericInput
          onButtonClick={changeHandler}
          onValueChange={changeHandler}
          value={this.props.stateManager.getFamilyHelper().getSiblingCount(personId, siblingSex, fullOrHalf)}
          large={true}
          minorStepSize={null}
          min={0}
          max={30}
          fill={true}
          className='siblingInput'
        />
      </FormGroup>
    );
  }

  handleCountChange(siblingSex, fullOrHalf, targetPerson, newCount) {
    const targetPersonArray = []
    targetPersonArray.push('proband')
    if (['mother', 'father'].includes(targetPerson)){
      targetPersonArray.push(targetPerson)
    }
    this.props.stateManager.setSiblingCount(this.resolvePersonIdWithCreate, siblingSex, fullOrHalf, newCount, targetPersonArray);
  }
}

export default PersonSiblingsStep;