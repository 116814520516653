import React from 'react'
import { Callout, NonIdealState, Spinner } from '@blueprintjs/core';

function Home({fatalErrorMessage, loggingIn}){
  return (
    <div>      
      { (!fatalErrorMessage)
      ?
      (loggingIn 
          ? 
        <div className="bp3-non-ideal-state">
          <div className="bp3-non-ideal-state-visual">
            <Spinner />
          </div>
            <h4 className="bp3-heading">Logging you in...</h4>
        </div> 
          : 
        <NonIdealState
          icon='crown'
          title='Welcome to the Pre-Visit Questionnaire!'
          description='To log in, please paste the link provided to you by your service provider in the url bar.'
          />
        )
      : 
      <div>
        <NonIdealState
          icon='error'
          title='Unable to Load Questionnaire'
          description='Please try again in a few minutes or reach out to your service provider if the problem continues.'
        />
        <Callout intent="danger" title="Error Message">
            {fatalErrorMessage}
        </Callout>
      </div>
      }
    </div>
    )
  }

 export default Home;