import React, { Component } from 'react';
import { FormGroup, TextArea } from '@blueprintjs/core';
import { getLabel } from '../utils/helpers';
import Context from '../utils/context/Context'


class AdditionalInformation extends Component {
  static contextType = Context
  constructor(props) {
    super(props);
    this.state = {
    }

  }

  handleTextChange = (val) => {
    this.props.stateManager.modifyState((draftState) => {
      draftState.notes = val
    });
  }

  render() {
    return (
      <React.Fragment>
        <FormGroup
          label={getLabel(this.context.localization, `additionalInformation`) }
        >
          <TextArea
            maxLength="65000"
            onChange={(e) =>
              this.handleTextChange(e.target.value)
            }
            value={this.props.cptState.notes || ''}
          />
        </FormGroup>
      </React.Fragment>
    );
  }
}

export default AdditionalInformation;