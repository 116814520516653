import React, { Component } from 'react';
import { Icon } from '@blueprintjs/core';
import _ from 'lodash';

import { getParentStr, getParentSex, getOffspringStr, getSiblingStr } from '../utils/helpers.js'
import { PersonFieldPaths } from '../utils/QuestionnaireStateManager.js';
import FamilyHelper from "../utils/FamilyHelper.js";

import MultiPersonEntry from '../widgets/MultiPersonEntry'
import LifeStatus from '../widgets/LifeStatus'
import HeartProblems from '../widgets/HeartProblems'
import FullName from '../widgets/FullName'

const { SEX, FIRST_NAME } = PersonFieldPaths;

class CousinInfoStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lifeStatusFieldPath : {
        maternal: 'properties.areAllMaternalCousinsAlive',
        paternal: 'properties.areAllPaternalCousinsAlive',
      },
      cousinPersonIds: this.getCousinPersonIds(this.props.person.id)
    }
  }
  
  render() {
    return (
      <React.Fragment>
        <div className="mainLabel">
          <p>For each of the patient's cousins on their {getParentStr(this.props.side)}'s side, please edit (using the  <Icon icon="edit" intent="warning" iconSize='16' />  icon) their information if they either:</p>
          <ol>
            <li>Are Deceased</li>
            <span className="explainerText pl-20"> - or - </span>
            <li>Have experienced any heart problems.</li>
          </ol>
        </div>
          <MultiPersonEntry
            personIds={this.state.cousinPersonIds}
            entryRenderer={this.renderCousinEntry.bind(this)}
            personLabel={this.getCousinPersonLabel}
            stateManager={this.props.stateManager}
            getValue={this.props.stateManager.getPersonValue.bind(this.props.stateManager)}
          />
      </React.Fragment>
    );
  }
  
  renderCousinEntry = (cousinPersonId) => {
    return(
      <div key={cousinPersonId}>
          <FullName
            id={cousinPersonId}
            getValue={this.props.stateManager.getPersonValue.bind(this.props.stateManager)}
            setValue={this.props.stateManager.setPersonValue.bind(this.props.stateManager)}
            personIdWithCreate={_.partial(this.resolveCousinPersonIdWithCreate, cousinPersonId)}
          />
          <LifeStatus 
            personId={cousinPersonId}
            personIdWithCreate={_.partial(this.resolveCousinPersonIdWithCreate, cousinPersonId)}
            stateManager={this.props.stateManager}
            />
          <HeartProblems 
            personId={cousinPersonId}
            personIdWithCreate={_.partial(this.resolveCousinPersonIdWithCreate, cousinPersonId)}
            stateManager={this.props.stateManager}
            />
      </div>
    )
  }
    
  handleQuestionPropChange = (e) => {
    this.props.stateManager.setPersonValue(this.props.person.id, this.state.lifeStatusFieldPath[this.props.side], e.target.value);
  }
  
  getCousinPersonIds = (id) => {
    let cousins = this.props.stateManager.getFamilyHelper()
      .getFirstCousins(id , getParentSex(this.props.side));
    return _.map(cousins, (cousin) => cousin.id);
  }

  getCousinPersonLabel = (personId) => {
    const famHelperNoCreate = new FamilyHelper(this.props.stateManager.cpt.state);

   
    const cousinRelStr = getOffspringStr(this.props.stateManager.getPersonValue(personId, SEX))
    const auncleIds = ['M', 'F'].map((sex) => famHelperNoCreate.resolveParent(personId, sex, false))
                                .filter(person => person.id);
    if (auncleIds > 1){
      console.error('More than one parent detected')
      //TODO - deal with the case of knowing more than one parent - ie consignuginty or a more complete family tree. 
    }
    const auncleName = this.props.stateManager.getPersonValue(auncleIds[0].id, FIRST_NAME);
    const auncleRelStr = getSiblingStr(this.props.stateManager.getPersonValue(auncleIds[0].id, SEX));
    const parentId = this.getParentPersonId(famHelperNoCreate, false); 
    const parentFirstName = this.props.stateManager.getPersonValue(parentId, FIRST_NAME);
    const parentsLabel = parentFirstName || getParentStr(this.props.side);

    return auncleName ? `${auncleName}'s ${cousinRelStr}` : `${parentsLabel}'s ${auncleRelStr}'s ${cousinRelStr}`;

  }
  //TODO: never gets called because there is no situation in which the cousin does not exist when they have info being filled out
  resolveCousinPersonIdWithCreate = (cousinPersonId, state) => {
    const famHelper = new FamilyHelper(state);
    if (!famHelper.doesPersonExist(cousinPersonId)) {
      famHelper.addCousin(this.getParentPersonId(famHelper), cousinPersonId);
    }
    return cousinPersonId;
  }

  getParentPersonId = (famHelper, create = true) => {
    const parentPerson = famHelper.resolveParent(this.props.person.id, getParentSex(this.props.side), create)
    return _.get(parentPerson, 'id')
  }
}


export default CousinInfoStep;