import produce from 'immer';
import _ from 'lodash';
import uuidv4 from 'uuid/v4';
import request from 'superagent';

import FamilyHelper from './FamilyHelper.js';
import isValueEmpty from './helpers';

/**
 * Helper class to handle state management for the `Questionnaire` component. The purpose of this helper is to avoid
 * mixing component rendering code with state manipulation code, and to keep the Questionnaire component file from
 * becoming too big.
 * 
 * A few ground rules:
 *  - For empty values, the backend may either leave the keys undefined, or send null. This code should properly handle
 *    both of those possibilities.
 *  - When we set values to empty, we prefer to unset them (i.e. set them to undefined) over setting them to null.
 *  - null represents "the user has not entered a value for this" OR "the user has entered an empty value for this". 
 *    There is no way to distinguish between them.
 */
class QuestionnaireStateManager {
  constructor(questionnaireInstance) {
    this.cpt = questionnaireInstance; // cpt is shortform for "component instance"
    this.setState = this.cpt.setState.bind(this.cpt); // shortcut for easier use

    _.bindAll(this, [
      'getPersonById',
      'getProband'
    ]);
  }

  getState() {
    return this.cpt.state;
  }

  /** Person helpers */

  getPersonById(id) {
    return QuestionnaireStateManager.getPersonById(id, this.cpt.state);
  }

  getProband() {
    return QuestionnaireStateManager.getProband(this.cpt.state);
  }

  /**
   * Sets the value at the given path in the given Person object.
   * This function should only be used to set strings, numbers, or booleans, NOT objects.
   * 
   * This function might seem simple, but it actually has some special logic to handle creation of objects within
   * arrays. This is explained below.
   * 
   * `path` is a lodash-style object path descriptor that can contain object key and array index references, e.g.
   * a.b[0].c.d[1].e
   * 
   * This function, however, extends the path descriptor further to allow substitution of variable indices using an
   * array search. This is what the `arrayIdxSearchCriteria` argument is used for. 
   * 
   * A `path` can be used along with `arrayIdxSearchCriteria` with substitution values like in this example:
   * `path`: a.b[bIndex].c.d[dIndex].e
   * `arrayIdxSearchCriteria`: {
   *    bIndex: { id: 1 },
   *    dIndex: { id: 2 },
   * }
   * In that example, the array at path a.b will be searched for an object that has { id: 1 }. If it is found, the array
   * index of the matched object will be substituted for `bIndex`. If it is not, a new object will be created, added to
   * the array, and its index will be substituted for `bIndex`.
   * The same will be done for `dIndex`.
   * 
   * If a new object needs to be created:
   * 1) `QuestionnaireStateManager.PersonConstructors` will first be checked for a constructor function, at the key 
   * corresponding to the array's path. That constructor will be called to create a new object.
   * 2) The search criteria will be merged into the newly constructed object.
   * 
   * @param {string|function} personId The ID of the Person object OR a function that returns the ID of the Person 
   *    object, which will be called with the state object as an argument
   * @param {string} path The path within the object, e.g. lifeStatus.alive
   * @param {*} value The value to set at the requested path.
   *    an empty value to unset the value at the requested path
   * @param {object} arrayIdxSearchCriteria An object holding search criteria for substitution values in the `path`
   *    argument. (See above for more details)
   */
  setPersonValue(personId, path, value, arrayIdxSearchCriteria) {
    this.setState(produce((draftState) => {
      let resolvedPersonId = _.isFunction(personId) ? personId(draftState) : personId;
      let person = QuestionnaireStateManager.getPersonById(resolvedPersonId, draftState);

      const resolvedPath = this.resolvePath(person, path, arrayIdxSearchCriteria, true);

      if (isValueEmpty(value)) {
        _.unset(person, resolvedPath);
      } else {
        if (_.isObject(value)) {
          console.warn("setPersonValue should not be called to set objects.");
        }

        _.set(person, resolvedPath, value);
      }
    }));
  }

  /**
   * Resolves substitution values within a path string, optionally also creating new arrays and objects along the way.
   * See `setPersonValue` for the motivation behind doing this.
   * @param {object} obj The object within which to resolve the path
   *    If `create` is `true`, then new objects/arrays will also be created inside this object.
   * @param {string} path The path to resolve
   * @param {object} arrayIdxSearchCriteria An object holding search criteria for substitution values in the `path`
   *    argument (see docs for `setPersonValue`)
   * @param {boolean} create Should new arrays and objects be created in case they don't exist?
   * @returns {string} the path string with all substitution values substituted
   */
  resolvePath(obj, path, arrayIdxSearchCriteria, create) {
    if (_.isEmpty(arrayIdxSearchCriteria)) {
      return path;
    }

    let resolvedPath = path;
    let match, idxLabel, resolvedIdx, targetArr, targetArrPath;
    while ((match = (new RegExp(/\[([^\]\d]*)\]/g)).exec(resolvedPath))) {
      idxLabel = match[1];
      targetArrPath = resolvedPath.substring(0, match.index);
      targetArr = _.get(obj, targetArrPath);

      if (!targetArr) {
        targetArr = [];
      }

      const searchCriteriaObj = arrayIdxSearchCriteria[idxLabel];
      resolvedIdx = _.findIndex(targetArr, searchCriteriaObj);

      if (resolvedIdx === -1) {
        resolvedIdx = targetArr.length;

        if (create && QuestionnaireStateManager.PersonConstructors[targetArrPath]) {
          const constructFn = QuestionnaireStateManager.PersonConstructors[targetArrPath];
          let newObj = constructFn(obj);
          _.assign(newObj, searchCriteriaObj); // merge the search criteria values into the new object
          _.set(obj, targetArrPath + '[' + resolvedIdx + ']', newObj);
        }
      }

      resolvedPath = resolvedPath.replace('[' + idxLabel + ']', '[' + resolvedIdx + ']');
    }

    return resolvedPath;
  }

  /**
   * Returns the value at the given path in the given Person object.
   * @param {string} personId The ID of the Person object
   * @param {string} path The path within the object, e.g. lifeStatus.alive
   * @returns the requested value
   *    undefined if the value is not found
   */
  getPersonValue(personId, path, arrayIdxSearchCriteria) {
    if (!personId) {
      return undefined;
    }

    const person = this.getPersonById(personId);
    const resolvedPath = this.resolvePath(person, path, arrayIdxSearchCriteria);
    return _.get(person, resolvedPath);
  }

  /**
   * Adds a value to the array in the person object at the given path. If the array does not exist, it is created.
   * @param {string} personId The ID of the Person object
   * @param {string} path The path within the object, e.g. ancestry.paternal
   * @param {string} value The value to add
   */
  addToPersonArray(personId, path, value) {
    this.setState(produce((draftState) => {
      let person = QuestionnaireStateManager.getPersonById(personId, draftState);
      let target = _.get(person, path);
      if (!target || !_.isArray(target)) {
        target = [];
      }
      target.push(value);
      _.set(person, path, target);
    }));
  }

  /**
   * Removes a value from the array in the person object at the given path.
   * @param {string} personId The ID of the Person object
   * @param {string} path The path within the object, e.g. ancestry.paternal
   * @param {string} value The value to remove
   *    null or undefined to unset the value at the requested path
   */
  removeFromPersonArray(personId, path, value) {
    this.setState(produce((draftState) => {
      let person = QuestionnaireStateManager.getPersonById(personId, draftState);
      let target = _.get(person, path);
      if (!target || !_.isArray(target)) {
        return; // nothing for us to do
      }

      if (value === null || value === undefined) {
        _.unset(person, path);
      } else {
        _.remove(target, (val) => val === value);
      }
    }));
  }

  removeFromArrayInPath(personId, path, index) {
    this.setState(produce((draftState) => {
      let person = QuestionnaireStateManager.getPersonById(personId, draftState);
      let target = _.get(person, path);
      if (!target || !_.isArray(target)) {
        return; // nothing for us to do
      }

      if (index !== null || index !== undefined) {
        delete target[index];
        const filterNull = target.filter(item => item);
        _.set(person, path, filterNull);
      }
    }));
  }

  /**
   * Directly modifies the state using a provided callback function. This function provides more flexibility, but also
   * more opportunity for error, so it should only be used when the usage falls outside of other, more specific
   * functions provided by this class.
   * @param {function} callback The callback to call with `draftState`. The handler should make the desired 
   *    modifications to `draftState`.
   */
  modifyState(callback) {
    this.setState(produce(callback));
  }

  /** Field-specific helpers */
  setPersonConditionValues(personId, mergeObj, searchCondition) {
    const createFn = (mergeObj) => {
      let newObj = {
        id: uuidv4()
      };
      _.assign(newObj, mergeObj);
    };
    this.setPersonArrayObjectValues(personId, PersonFieldPaths.CONDITIONS, mergeObj, searchCondition, createFn);
  }

  getPersonAncestryPath(side) {
    if (side === "maternal") {
      return PersonFieldPaths.MATERNAL_ANCESTRY;
    } else if (side === "paternal") {
      return PersonFieldPaths.PATERNAL_ANCESTRY;
    } else {
      return null;
    }
  }

  /** Family manipulation */
  getFamilyHelper() {
    return new FamilyHelper(this.cpt.state);
  }

  setSiblingCount(personId, siblingSex, numSharedParents, newCount, targetPersonArray) {
    this.setState(produce((draftState) => {
      let resolvedPersonId = _.isFunction(personId) ? personId(draftState) : personId;
      (new FamilyHelper(draftState)).setSiblingCount(resolvedPersonId, siblingSex, numSharedParents, newCount, targetPersonArray);
    }));
  }

  setHalfSiblingSharedParentType(personId, siblingId, sharedParentSex) {
    this.setState(produce((draftState) => {
      let resolvedPersonId = _.isFunction(personId) ? personId(draftState) : personId;
      (new FamilyHelper(draftState)).setHalfSiblingSharedParentType(resolvedPersonId, siblingId, sharedParentSex);
    }));
  }

  setTwinRelationship(personId, probandId, relPath){
    const TwinProperties = {
      monozygoticTwin: 'monozygoticTwin',
      dizygoticTwin: 'dizygoticTwin'
    }
    const relationshipPath = TwinProperties[relPath]
    this.setState(produce((draftState) => {
      let resolvedPersonId = _.isFunction(personId) ? personId(draftState) : personId;
      (new FamilyHelper(draftState)).setTwinRelationship(resolvedPersonId, probandId, relationshipPath);
    }));
  }

  setChildCount(personId, childSex, newCount, targetPersonArray) {
    this.setState(produce((draftState) => {
      let resolvedPersonId = _.isFunction(personId) ? personId(draftState) : personId;
      (new FamilyHelper(draftState)).setChildCount(resolvedPersonId, childSex, newCount, targetPersonArray);
    }));
  }

  removePerson(personId) {
    this.setState(produce((draftState) => {
      const famHelper = new FamilyHelper(draftState);
      if (famHelper.doesPersonExist(personId)) {
        famHelper.removePerson(personId);
      }
    }));
  }

  setRelationshipProperty(getRelationship, propPath, propVal) {
    this.setState(produce((draftState) => {
      let resolvedRel =  getRelationship(draftState);
      if (resolvedRel) {
        _.set(resolvedRel, propPath, propVal);
      }
    }));
  }

  /** Persistence */
  loadFromBackend() {
    request
      .get('/api/v1/questionnaire/current-session')
      .accept('json')
      .then(res => {
        if (!res.body.pedigree.persons[res.body.pedigree.probandId].relationshipToProband) {
          res.body.pedigree.persons[res.body.pedigree.probandId].relationshipToProband = 'proband'
        }
        this.setState(res.body.pedigree);
      })
      .catch(()=> {
        //TODO: how to keep the 404 error from appearing in the console. 
      })
  }
}

QuestionnaireStateManager.PersonFieldPaths = {
  ID: 'id',
  ALIVE_STATUS: 'lifeStatus.alive',
  CAUSE_OF_DEATH: 'lifeStatus.causeOfDeath',
  AGE_OF_DEATH: 'lifeStatus.ageOfDeath',
  POST_MORTEM_PERFORMED: 'lifeStatus.postMortemPerformed',
  PATERNAL_ANCESTRY: 'ancestry.paternal',
  MATERNAL_ANCESTRY: 'ancestry.maternal',
  FIRST_NAME: 'name.firstName',
  LAST_NAME: 'name.lastName',
  LAST_NAME_AT_BIRTH: 'name.lastNameAtBirth',
  SEX: 'sex',
  GENDER_IDENTITY: 'genderIdentity',
  FIRST_MENSTRUAL_PERIOD: 'cancerRiskData.ageAtMenarche',
  ORAL_CONTRACEPTIVE_USAGE: 'cancerRiskData.oralContraceptiveUsage',
  MENOPAUSE_STATUS: 'cancerRiskData.menopauseStatus',
  AGE_AT_MENOPAUSE: 'cancerRiskData.ageAtMenopause',
  HRT_USAGE: 'cancerRiskData.hrtUsage',
  HRT_TYPE: 'cancerRiskData.hrtType',
  TOBACCO_USAGE: 'cancerRiskData.smoking',
  AVERAGE_CIGARETTES_DAY: 'cancerRiskData.avgCigarettes',
  TOBACCO_TOTAL_YEARS: 'cancerRiskData.smokingYears',
  ALCOHOL_USAGE: 'cancerRiskData.consumesAlcohol',
  DRINKING_FRECUENCY: 'cancerRiskData.alcoholConsumptionDetails[0].drinkingFrequency',
  DRINK_AMOUNT: 'cancerRiskData.alcoholConsumptionDetails[0].drinkAmount',
  ENDOMETRIOSIS_DIAGNOSED: 'endometriosisDiagnosed',
  DIAGNOSTIC_TESTS: 'diagnosticTests',
  DIAGNOSTIC_TEST_TYPE: 'diagnosticTests.type',
  SURGERIES: 'surgeries',
  BREAST_BIOPSY_STATUS: 'breastBiopsyStatus',
  BREAST_BIOPSY_RESULTS: 'breastBiopsyResults',
  PROSTATE_BIOPSY_STATUS: 'prostateBiopsyStatus',
  ELEVATED_PSA_STATUS: 'elevatedPSAStatus',
  GENE_TESTS: 'cancerRiskData.geneTests',
  ADOPTED_STATUS: 'adopted',
  DATE_OF_BIRTH: 'dateOfBirth',
  RELATIONSHIP_TO_PROBAND: 'relationshipToProband',
  CONDITIONS: 'conditions',
  CONDITION_CONDITION_NAME: 'conditions[conditionIdx].conditionName',
  CONDITION_IS_PRESENT: 'conditions[conditionIdx].isPresent',
  CONDITION_DESCRIPTION: 'conditions[conditionIdx].description',
  CANCERS: 'cancers',
  CANCERS_CANCER_ID: 'cancers[cancerIdx].id',
  CANCERS_CANCER_LABEL: 'cancers[cancerIdx].label',
  CANCERS_CANCER_AFFECTED: 'cancers[cancerIdx].affected',
  CANCERS_CANCER_QUALIFIERS: 'cancers[cancerIdx].qualifiers',
  GENETIC_TESTING_PERFORMED: 'geneticTesting.performed',
  GENETIC_TESTING_DESCRIPTION: 'geneticTesting.description',
  PROPERTIES: 'properties',
  PROPERTY_TYPE: 'properties[propertyIdx].type',
  PROPERTY_IS_PRESENT: 'properties[propertyIdx].isPresent',
  PROPERTY_VALUE: 'properties[propertyIdx].value',
};

QuestionnaireStateManager.StateFieldPaths = {
  PEOPLE: 'persons',
  RELATIONSHIPS: 'relationships',
  PROBAND_ID: 'probandId',
  NOTES: 'notes'
};

/**
 * These constructor functions return a newly constructed object for those parts of the Person object that are stored as
 * objects inside an array. The keys of the object below correspond to the path of the array within the Person object.
 */
QuestionnaireStateManager.PersonConstructors = {
  'conditions': () => {
    return {
      id: uuidv4()
    };
  },

  'properties': () => {
    return {
    };
  }
};

/**
 * Returns a new Person object, with ID initialized as a UUID.
 */
QuestionnaireStateManager.getNewPerson = (newId) => {
  return {
    [PersonFieldPaths.ID]: newId || uuidv4()
  };
}

/**
 * Returns the last path component (i.e. the field name) from the given path.
 */
QuestionnaireStateManager.getFieldName = (path) => {
  const pathArr = _.toPath(path);
  return pathArr[pathArr.length - 1];
}

/**
 * Return an empty state object to use for initializing the state object. Creates a "people" object and adds an empty
 * proband to it.
 */
QuestionnaireStateManager.getEmptyState = () => {
  const proband = QuestionnaireStateManager.getNewPerson();
  var state = {};
  state[StateFieldPaths.PROBAND_ID] = proband.id;
  state[StateFieldPaths.PEOPLE] = {};
  state[StateFieldPaths.PEOPLE][proband.id] = proband;
  state[StateFieldPaths.PEOPLE][proband.id].relationshipToProband = 'proband';
  state[StateFieldPaths.RELATIONSHIPS] = [];
  return state;
}

/**
 * Returns a Person object by the person's ID.
 */
QuestionnaireStateManager.getPersonById = (id, state) => {
  return state[StateFieldPaths.PEOPLE][id];
}

/**
 * Returns the proband's Person object.
 */
QuestionnaireStateManager.getProband = (state) => {
  return QuestionnaireStateManager.getPersonById(state.probandId, state);
}

QuestionnaireStateManager.isPersonEmpty = (person) => {
  let clone = _.clone(person);
  _.unset(clone, PersonFieldPaths.ID);
  _.unset(clone, PersonFieldPaths.SEX);
  _.unset(clone, PersonFieldPaths.RELATIONSHIP_TO_PROBAND);
  if (_.keys(clone).length === 0){
    return true
  } else if (_.keys(clone).filter((key) => !_.isEmpty(clone[key])).length === 0) {
    return true
  }
  return false;
}

const PersonFieldPaths = QuestionnaireStateManager.PersonFieldPaths;
const StateFieldPaths = QuestionnaireStateManager.StateFieldPaths;
const getFieldName = QuestionnaireStateManager.getFieldName;

export { QuestionnaireStateManager, PersonFieldPaths, getFieldName, StateFieldPaths };
