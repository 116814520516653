import React, { useContext } from 'react';
import ConditionalSection from './ConditionalSection'
import DeathDetails from './DeathDetails'
import { PersonFieldPaths } from '../utils/QuestionnaireStateManager.js';
import { getLabel } from '../utils/helpers';
import Context from '../utils/context/Context'



export default function LifeStatus({ personId, stateManager, personIdWithCreate}){
  const { CAUSE_OF_DEATH, AGE_OF_DEATH, POST_MORTEM_PERFORMED, ALIVE_STATUS } = PersonFieldPaths
  const { localization } = useContext(Context);
  return(
    <ConditionalSection
      label={getLabel(localization, ALIVE_STATUS) }
      showWhenCondition='N'
      conditionState={stateManager.getPersonValue(personId, ALIVE_STATUS)}
      onConditionStateChange={(e) => stateManager.setPersonValue(personIdWithCreate, ALIVE_STATUS, e.target.value)}
      orderSchema={['Y', 'N', 'U']}
      cleanUpChildQuestions={() => {
        [CAUSE_OF_DEATH, AGE_OF_DEATH, POST_MORTEM_PERFORMED].forEach(path => stateManager.setPersonValue(personIdWithCreate, path, null))
      }}
    >
      <DeathDetails
        getPersonValue={(path) => stateManager.getPersonValue(personId, path)}
        setPersonValue={(path, val) => stateManager.setPersonValue(personIdWithCreate, path, val)} />
    </ConditionalSection>
  )
}