export const CANCER_CATEGORIES = [
  { name: "Breast", label: "Neoplasm of the breast", id: "HP:0100013" },
  {
    name: "Ovarian",
    label: "Ovarian neoplasm",
    id: "HP:0100615",
    genderRestriction: "F",
  },
  { name: "Colon", label: "Colon cancer", id: "HP:0003003" },
  {
    name: "Endometrial (uterus)",
    label: "Uterine neoplasm",
    id: "HP:0010784",
    genderRestriction: "F",
  },
  {
    name: "Prostate",
    label: "Prostate cancer",
    id: "HP:0012125",
    genderRestriction: "M",
  },
  { name: "Pancreatic", label: "Neoplasm of the pancreas", id: "HP:0002894" },
  { name: "Skin", label: "Neoplasm of the skin", id: "HP:0008069" },
  {
    name: "Kidney and urinary tract",
    label: "Urinary tract neoplasm",
    id: "HP:0010786",
  },
  {
    name: "Gastric (stomach)",
    label: "Neoplasm of the stomach",
    id: "HP:0006753",
  },
  { name: "Brain", label: "Brain neoplasm", id: "HP:0030692" },
  { name: "Liver", label: "Neoplasm of the liver", id: "HP:0002896" },
  {
    name: "Small intestine",
    label: "Neoplasm of the small intestine",
    id: "HP:0100833",
  },
  {
    name: "Other",
    label: "Other",
    id: null,
  }
];

export const BREAST_TYPES = [
  { name: "Breast cancer", label: "Breast carcinoma", id: "HP:0003002"},
  { name: "DCIS (ductal carcinoma in situ)", label: "Ductal carcinoma in situ", id: "HP:0030075"},
  { name: "LCIS (lobular carcinoma in situ)", label: "Lobular carcinoma in situ", id: "HP:0030076"},
];

export const OVARIAN_TYPES = [
  { name: "High-grade serous ovarian cancer clear cell, endometroid, low-grade serous or mucinous ovarian cancer", label: "Ovarian carcinoma", id: "HP:0025318"},
  { name: "Fallopian tube cancer", label: "Fallopian tube carcinoma", id: "HP:0030394"},
  { name: "Cancer that started in the peritoneum", label: "Primary peritoneal carcinoma", id: "HP:0030406"},
  { name: "Other type of ovarian cancer", label: "Other ovarian cancer (non-epithelial)", id: ""},
];

export const SKIN_TYPES = [
  { name: "Basal Cell Carcinoma", label: "Basal cell carcinoma", id: "HP:0002671"},
  { name: "Squamous Cell Carcinoma", label: "Squamous cell carcinoma", id: "HP:0002860"},
  { name: "Sebaceous Gland Carcinoma", label: "Sebaceous gland carcinoma", id: "HP:0030410"},
  { name: "Leiomyoma", label: "Cutaneous leiomyoma", id: "HP:0007620"},
  { name: "Melanoma", label: "Cutaneous melanoma", id: "HP:0012056"},
];

export const KIDNEY_AND_URINARY_TYPES = [
  { name: "Kidney cancer", label: "Renal neoplasm", id: "HP:0009726"},
  { name: "Ureter cancer", label: "Neoplasm of the ureter", id: "HP:0100516"},
  { name: "Bladder cancer", label: "Bladder neoplasm", id: "HP:0009725"},
  { name: "Bile duct cancer", label: "Cholangiocarcinoma", id: "HP:0030153"},
];

export const BRAIN_TYPES = [
  { name: "Astrocytoma", label: "Astrocytoma", id: "HP:0009592"},
  { name: "Glioblastoma", label: "Glioblastoma multiforme", id: "HP:0012174"},
  { name: "Medulloblastoma", label: "Medulloblastoma", id: "HP:0002885"},
];

export const COLON_TYPES = [
  { name: "Rectum", label: "Neoplasm of the rectum", id: "HP:0100743"}
];

export const OTHER_TYPES = [
  { name: "Adrenocortical tumour", label: "Adrenocortical Carcinoma", id: "HP:0006744"},
  { name: "Cervix", label: "Cervix cancer", id: "HP:0030079"},
  { name: "Hodgkin lymphoma", label: "Hodgkin lymphoma", id: "HP:0012189"},
  { name: "Leukemia", label: "Leukemia", id: "HP:0001909"},
  { name: "Lung", label: "Neoplasm of the lung", id: "HP:0100526"},
  { name: "Medulloblastoma", label: "Medulloblastoma", id: "HP:0002885"},
  { name: "Osteosarcoma", label: "Osteosarcoma", id: "HP:0002669"},
  { name: "Retinoblastoma", label: "Retinoblastoma", id: "HP:0009919"},
  { name: "Sarcoma", label: "Sarcoma", id: "HP:0100242"},
  { name: "Thyroid", label: "Neoplasm of the thyroid gland", id: "HP:0100031"}
]

export const BREAST_LATERALITY = [
  { name: "Left only", id: "left" },
  { name: "Right only", id: "right" },
  { name: "Both sides", id: "bothSides" },
]