import React, { useState } from 'react'
import { Button, Icon } from '@blueprintjs/core';
import ConditionIndicators from './ConditionIndicators';
import classnames from 'classnames';

export default function InternalCard({ fullName, relationship, removePersonEntry, children, medicalConditions, opened}) {
  const [entryRenedererOpen, toggleEntryRenederer] = useState(opened);
  return(
      <div className="person-entry">
        <div
          className={classnames('cardTitle', 'cardTitle-white', 'cardTitle-left', 'cardTitle-clickable', { 'card-open' : entryRenedererOpen })}
          onClick={() => toggleEntryRenederer(!entryRenedererOpen)}
        >
          <div className="d-flex align-items-center">
            <div className="round">
            <Icon icon="person" color='#0C426A' iconSize='12' className="cardTitleIcon" />
            </div>
            <div className="person-entry-info">
              <h4>{fullName}</h4>
              <p className="subLabel">{relationship}</p>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <ConditionIndicators 
              medicalConditions={medicalConditions}
            />
            <Button
              rightIcon="cross"
              intent="danger"
              minimal={true}
              onClick={removePersonEntry}
            />
          </div>
        </div>
        {entryRenedererOpen ?
          <div className='cardBody cardBody-inner'>
            {children}
          </div>
          : null}
      </div>
  )
}

