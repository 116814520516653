import React from 'react';

function IntroductionStep () {
    return (
      <div className="bodyText">
        <p>Before your appointment, it is helpful for us to learn more about your medical and family history.</p>
        <p>This information will help us to provide the best care for you. Please try to complete as many of the questions as possible.</p>
        <p>The more details you can provide, the more accurate we can be in our assessment.</p>
        <p>It is important to include family members that are alive AND deceased.It is also important to include family members who have had cancer AND those who do not have a history of cancer</p>
        {/* <p>Before we meet your family, it helps us to learn about your family history.</p>  
        <p>Since the heart conditions we treat are often passed down through families, what we learn about your family history can be the key to unlocking a diagnosis or making the best care plan for your family.</p>
        <p>Knowing our patients’ family history also helps us plan care for each patient, judge risk of serious heart events, and saves time on the day of the visit.</p> 
        <p>This survey will ask about the patient’s brothers and sisters, parents, cousins, grandparents, and other relatives with heart problems.</p> */}
      </div>
    );
}

export default IntroductionStep;