import React, { Component } from 'react';
import { FormGroup, TextArea, RadioGroup, Radio, NumericInput, Callout, Checkbox } from '@blueprintjs/core';
import _ from 'lodash';

import ConditionalSection from '../widgets/ConditionalSection';
import Chooser from '../widgets/Chooser';
import DOBInput from '../widgets/DOBInput';
import LifeStatus from '../widgets/LifeStatus';
import FullName from '../widgets/FullName';

import Context from '../utils/context/Context'
import { getLabel, getSiblingStr, getParentStrFromSex, capitalize } from '../utils/helpers';

import { getFieldName, PersonFieldPaths as paths } from '../utils/QuestionnaireStateManager.js';
import '../../node_modules/@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import HeartProblems from '../widgets/HeartProblems';
import MultipleConditionalSection, { CONDITIONS } from '../widgets/MultipleConditionalSection';
import CancerHistory from '../widgets/CancerHistory';

class PersonStep extends Component { 
  static contextType = Context
  constructor(props) {
    super(props);
    this.resolvePersonIdWithCreate = _.partial(this.props.resolvePersonId, true);
    let geneTests = props.stateManager.getPersonValue(this.getPersonId(), paths.GENE_TESTS);

    this.state = {
      hasBeenDiagnosedWithCancer: "",
      isPositiveForGenes: geneTests.length > 0 ? 'Y' : null,
      hrtTypes: []
    }
  }

  render() {
    const {sex, type, isHalfSibling, targetPerson} = this.props;
    const targetPersonArray = this.props.siblingOfLabel ? ['proband', this.props.siblingOfLabel.toLowerCase(), getSiblingStr(this.props.sex)] : ['proband']
    const probandSex = this.props.stateManager.getPersonValue(this.getPersonId(), paths.SEX);
    
    return (
      <React.Fragment>
          {(type === 'sibling' || type === 'parentSibling') && isHalfSibling && this.renderSharedParentInfo()}
          {this.renderFullName(sex)}
          {this.renderDOBInput()}
          {!sex && this.renderSexChooser(type)}
          {type === 'proband' && this.renderDeclareTwins()}
          {type === 'sibling' && !isHalfSibling && this.doesProbandHaveATwin() && this.renderLinkTwins()}
          {type === 'proband' && probandSex === 'F' && this.renderFirstMenstrualPeriod()}
          {type === 'proband' && probandSex === 'F' && this.renderContraceptivePills()}
          {type === 'parentSibling' && this.renderChildrenCount(targetPersonArray)}
          {type === 'proband' && this.renderChildrenCount(targetPersonArray)}
          {type === 'proband' && probandSex === 'F' && this.renderMenopauseStatus()}
          {type === 'proband' && probandSex === 'F' && this.renderHRTUsed()}
          {type === 'proband' && this.renderTobaccoUsed()}
          {type === 'proband' && this.renderAlcoholUsed()}
          {type === 'proband' && this.renderCancerProblems()} 
          {type === 'proband' && probandSex === 'F' && this.renderEndometriosisDiagnosed()}
          {type === 'proband' && this.renderFITStatus()}
          {type === 'proband' && this.renderColonoscopyStatus()}
          {type === 'proband' && probandSex === 'F' && this.renderMammogramStatus()}
          {type === 'proband' && probandSex === 'F' && this.renderBreastBiopsyStatus()}
          {type === 'proband' && probandSex === 'M' && this.renderProstateBiopsyStatus()}
          {type === 'proband' && probandSex === 'M' && this.renderElevatedPSAStatus()}
          {type === 'proband' && probandSex === 'F' && this.renderFemaleSurgeries()}
          {type !== 'proband' && this.renderLifeStatus()}
          {type === 'proband' && this.renderHeartConditionDiagnosis()}
          {type !== 'proband' && this.renderHeartProblems()}
          {type === 'proband' && this.renderGeneticTestingInfo()}
          {type === 'proband' && this.renderGenesTestStatus()}
          {(type === 'proband' || targetPerson === 'mother' || targetPerson === 'father') && this.renderAdoptionInfo(targetPerson, type)}
      </React.Fragment>
    );
  }

  renderSharedParentInfo() {
    const { stateManager, siblingPersonId, resolvePersonId } = this.props
    const parentType = stateManager
        .getFamilyHelper()
        .getHalfSiblingSharedParentType(this.getPersonId(), siblingPersonId);
    const onChange = (e) => {

      
      const sharedParentSex = e.target.value;

      stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.PROPERTY_VALUE, capitalize(getParentStrFromSex(e.target.value)), {propertyIdx: { [getFieldName(paths.PROPERTY_TYPE)]: 'sharedParent' }})
      stateManager.setHalfSiblingSharedParentType(resolvePersonId, siblingPersonId,sharedParentSex);

    }
    return (
      <React.Fragment>
        <FormGroup>
          <RadioGroup
            onChange={onChange}
            selectedValue={parentType}
          >
            <Radio label={ getLabel(this.context.localization, 'properties.sharedParent.mother', 'person') } value='F' key='mother' className='bp3-large' />
            <Radio label={ getLabel(this.context.localization, 'properties.sharedParent.father', 'person') } value='M' key='father' className='bp3-large' />
          </RadioGroup>
        </FormGroup>
      </React.Fragment>
    );
  }

  renderDeclareTwins(){
    return(
      <Chooser 
        label={ getLabel(this.context.localization, 'properties.twin', 'patient') }
        onChange={ (e) => {
          this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.PROPERTY_IS_PRESENT, e.target.value, {propertyIdx: { [getFieldName(paths.PROPERTY_TYPE)]: 'twin' }})
        }}
        selectedValue={ this.props.stateManager.getPersonValue(this.getPersonId(), paths.PROPERTY_IS_PRESENT, {propertyIdx: { [getFieldName(paths.PROPERTY_TYPE)]: 'twin' }})}
        orderSchema={['Y', 'N', 'U']}
        path={'yesNoUnknown'}
        person={'patient'}
      />
    )
  }

  renderLinkTwins = () => {
    return(
       <Chooser 
        label={ getLabel(this.context.localization, 'properties.twin', 'person')}
        onChange={(e) => {
          this.props.stateManager.setTwinRelationship(this.resolvePersonIdWithCreate, this.props.stateManager.getProband().id, e.target.value)
          this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.PROPERTY_IS_PRESENT, e.target.value, {propertyIdx: { [getFieldName(paths.PROPERTY_TYPE)]: 'twin' }})
          }
        }
        selectedValue={
          this.props.stateManager.getPersonValue(this.getPersonId(), paths.PROPERTY_IS_PRESENT, {propertyIdx: { [getFieldName(paths.PROPERTY_TYPE)]: 'twin' }}) 
        }
        orderSchema={['monozygoticTwin', 'dizygoticTwin', 'N']}
        path={'properties.twin'}
      />
    )
  }

  renderFullName(sex) {
    return (
      <FullName
        id = {this.getPersonId()}
        getValue={this.props.stateManager.getPersonValue.bind(this.props.stateManager)}
        setValue={this.props.stateManager.setPersonValue.bind(this.props.stateManager)}
        personIdWithCreate = {this.resolvePersonIdWithCreate}
        sex={sex}
      />
    );
  }
  
  renderChildrenCount(targetPersonArray) {

    return (
      <ConditionalSection
      label={ getLabel(this.context.localization, 'properties.hasChildren') }
      showWhenCondition='Y'
      conditionState={this.props.stateManager.getPersonValue(this.getPersonId(), paths.PROPERTY_IS_PRESENT, {propertyIdx: { [getFieldName(paths.PROPERTY_TYPE)]: 'hasChildren' }})}
      onConditionStateChange={(e) => {
        this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.PROPERTY_IS_PRESENT, e.target.value, {propertyIdx: { [getFieldName(paths.PROPERTY_TYPE)]: 'hasChildren' }})
      }}
      orderSchema={['Y', 'N', 'U']}
      cleanUpChildQuestions={() => {
        ['M', 'F'].forEach(childSex => this.props.stateManager.setChildCount(this.resolvePersonIdWithCreate, childSex, 0, targetPersonArray))
      }}
      >
        {this.renderChildrenCountItem('M', targetPersonArray)}
        {this.renderChildrenCountItem('F', targetPersonArray)}
      </ConditionalSection>
    );
  }
  
  renderChildrenCountItem(childSex, targetPersonArray) {
    const sexLabel = {
      'M': 'boys',
      'F': 'girls'
    }[childSex];
    
    const changeHandler = (val) => this.props.stateManager.setChildCount(this.resolvePersonIdWithCreate, childSex, val, targetPersonArray);
    const children = this.props.stateManager.getFamilyHelper().getChildren(this.getPersonId(), childSex);

    return (
      <FormGroup label={"How many " + sexLabel + "?"}>
        <NumericInput
          onValueChange={changeHandler}
          value={children.length}
          minorStepSize={null}
          min={0}
          max={30}
        />
      </FormGroup>
    );
  }

  renderSexChooser(type) {
    if (type === 'proband') {
      return (
        <React.Fragment>
          <FormGroup
            label="Sex assigned at birth"
          >
            <RadioGroup
              onChange={(e) => {
                this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.SEX, e.target.value);
                this.cleanSexSpecificQuestions();
              }}
              selectedValue={ this.props.stateManager.getPersonValue(this.getPersonId(), paths.SEX)}
            >
              <Radio label="Female" value="F" key={"F"} className='bp3-large' />
              <Radio label="Male" value="M" key={"M"} className='bp3-large' />
            </RadioGroup>
          </FormGroup>
          <ConditionalSection
            // label={ getLabel(this.context.localization, paths.HAS_CHILDREN) }
            label={ 'My gender identity is different than this' }
            showWhenCondition='Y'
            conditionState={
              this.props.stateManager.getPersonValue(this.getPersonId(), paths.GENDER_IDENTITY) != null ? 'Y' : 'N'
            }
            onConditionStateChange={() => this.props.stateManager.setPersonValue(this.getPersonId(), paths.GENDER_IDENTITY, 'O')}
            orderSchema={['Y', 'N']}
            cleanUpChildQuestions={() => {
              this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.GENDER_IDENTITY, null);
            }}
          >
            <FormGroup
              label="What is your current identity?"
            >
              <RadioGroup
                onChange={(e) => this.props.stateManager.setPersonValue(this.getPersonId(), paths.GENDER_IDENTITY, e.target.value)}
                selectedValue={ this.props.stateManager.getPersonValue(this.getPersonId(), paths.GENDER_IDENTITY)}
              >
                <Radio label="Female" value="F" key={"F"} className='bp3-large' />
                <Radio label="Male" value="M" key={"M"} className='bp3-large' />
                <Radio label="Non-binary" value="nonBinary" key={"nonBinary"} className='bp3-large' />
                <Radio label="Other" value="O" key={"O"} className='bp3-large' />
              </RadioGroup>
            </FormGroup>
          </ConditionalSection>
        </React.Fragment>
      );
    } else {
      return (
        <Chooser
          selectedValue={ this.props.stateManager.getPersonValue(this.getPersonId(), paths.SEX)}
          onChange={(e) => this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.SEX, e.target.value)}
          orderSchema={['M', 'F', 'U', 'O']}
          path={'sex'}
        />
      );
    }
  }

  cleanSexSpecificQuestions() {
    this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.GENDER_IDENTITY, null);
    this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.FIRST_MENSTRUAL_PERIOD, null);
    this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.ORAL_CONTRACEPTIVE_USAGE, null)
    this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.MENOPAUSE_STATUS, null);
    this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.AGE_AT_MENOPAUSE, null);
    this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.HRT_USAGE, null);
    this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.HRT_TYPE, null);
    this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.ENDOMETRIOSIS_DIAGNOSED, null);
    const allSurgeries = this.props.stateManager.getPersonValue(this.getPersonId(), paths.SURGERIES);
    allSurgeries.forEach((_) =>
      this.props.stateManager.removeFromArrayInPath(this.getPersonId(), paths.SURGERIES, 0))
    const allDiagnosticTests = this.props.stateManager.getPersonValue(this.getPersonId(), paths.DIAGNOSTIC_TESTS);
    allDiagnosticTests.forEach((_) =>
      this.props.stateManager.removeFromArrayInPath(this.getPersonId(), paths.DIAGNOSTIC_TESTS, 0));
  }

  renderDOBInput() {
    return (
      <DOBInput
        dobDateStr={this.props.stateManager.getPersonValue(this.getPersonId(), paths.DATE_OF_BIRTH)}
        onChange={(date) => {
          // eslint-disable-next-line
          this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.DATE_OF_BIRTH, (date && date != 'Invalid Date') ? date.toISOString() : '')
        }}
      />
    );
  }

  renderLifeStatus() {
    return (
      <LifeStatus
        personId={this.getPersonId()}
        personIdWithCreate={this.resolvePersonIdWithCreate}
        stateManager={this.props.stateManager}
      />
    );
  }

  renderHeartConditionDiagnosis() {
    return (
      <HeartProblems
        personDesc='patient'
        personId={this.getPersonId()}
        personIdWithCreate={this.resolvePersonIdWithCreate}
        stateManager={this.props.stateManager}
        helperText={ getLabel(this.context.localization, 'conditions.heartCondition.description', 'patient', 'subLabel') }
      />
    )
  }
 
  renderHeartProblems() {
   return (
    <HeartProblems
      personId={this.getPersonId()}
      personIdWithCreate={this.resolvePersonIdWithCreate}
      stateManager={this.props.stateManager}
    />
   )
  }

  renderCancerProblems() {
    return (
      <ConditionalSection
      label="Have you ever been diagnosed with cancer?"
      showWhenCondition='Y'
      conditionState={this.state.hasBeenDiagnosedWithCancer}
      onConditionStateChange={(e) => {
        this.setState({hasBeenDiagnosedWithCancer: e.target.value})
      }}
      orderSchema={['Y', 'N', 'U']}
      cleanUpChildQuestions={() => {
        this.props.stateManager.setPersonValue(this.getPersonId(), paths.CANCERS, []);
      }}
      >
        <CancerHistory
          label="What type(s) of cancer?"
          personId={this.getPersonId()}
          stateManager={this.props.stateManager}
        ></CancerHistory>
      </ConditionalSection>
    )
  }

  renderGeneticTestingInfo() {
    return (
      <ConditionalSection
        label={ getLabel(this.context.localization, paths.GENETIC_TESTING_PERFORMED, 'patient') }
        showWhenCondition='Y'
        conditionState={this.props.stateManager.getPersonValue(this.getPersonId(), paths.GENETIC_TESTING_PERFORMED)}
        onConditionStateChange={(e) => this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.GENETIC_TESTING_PERFORMED, e.target.value)}
        orderSchema={['Y', 'N', 'U']}
        cleanUpChildQuestions={ () => this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.GENETIC_TESTING_DESCRIPTION, null) }
      >
        <FormGroup
          label={ getLabel(this.context.localization, paths.GENETIC_TESTING_DESCRIPTION, 'patient') }
        >
          <TextArea
            maxLength="65000"
            onChange={(e) => this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.GENETIC_TESTING_DESCRIPTION, e.target.value)}
            value={this.props.stateManager.getPersonValue(this.getPersonId(), paths.GENETIC_TESTING_DESCRIPTION) || ''}
          />
        </FormGroup>
      </ConditionalSection>
    );
  }

  renderFirstMenstrualPeriod() {
    return (
      <FormGroup label={"How old were you at the time of your first menstrual period?"}>
        <NumericInput
          onValueChange={(val) => this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.FIRST_MENSTRUAL_PERIOD, val)}
          value={this.props.stateManager.getPersonValue(this.getPersonId(), paths.FIRST_MENSTRUAL_PERIOD)}
        />
      </FormGroup>
    );
  }

  renderContraceptivePills() {
    return (
      <FormGroup
        label="Have you ever taken oral contraceptive pills?"
      >
        <RadioGroup
          onChange={(e) => this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.ORAL_CONTRACEPTIVE_USAGE, e.target.value)}
          selectedValue={ this.props.stateManager.getPersonValue(this.getPersonId(), paths.ORAL_CONTRACEPTIVE_USAGE)}
        >
          <Radio label="Never" value="never" className='bp3-large' />
          <Radio label="Unknown" value="unknown" className='bp3-large' />
          <Radio label="Stopped 2 or more years ago" value="stopped2" className='bp3-large' />
          <Radio label="Used within the last 2 years" value="usedWithin2" className='bp3-large' />
        </RadioGroup>
      </FormGroup>
    );
  }

  renderMenopauseStatus() {
    return (
      <div>
        <FormGroup
          label="Have you gone through menopause?"
        >
          <RadioGroup
            onChange={(e) => {
              const { value } = e.target;
              this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.MENOPAUSE_STATUS, value);
              if (value !== 'Y' || value !== 'C') {
                this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.AGE_AT_MENOPAUSE, null);
              }
            }}
            selectedValue={ this.props.stateManager.getPersonValue(this.getPersonId(), paths.MENOPAUSE_STATUS)}
          >
            <Radio label="Yes" value="Y" className='bp3-large' />
            <Radio label="Currently in menopause" value="inMenopause" className='bp3-large' />
            <Radio label="No" value="N" className='bp3-large' />
            <Radio label="Unknown" value="unknown" className='bp3-large' />
          </RadioGroup>
        </FormGroup>
        {this.renderAgeAtMenopause()}
      </div>
    );
  }

  renderAgeAtMenopause() {
    const menopauseStatus = this.props.stateManager.getPersonValue(this.getPersonId(), paths.MENOPAUSE_STATUS);
    if (menopauseStatus === 'Y' || menopauseStatus === 'C') {
      return (
        <FormGroup label={"Age at menopause?"}>
          <NumericInput
            onValueChange={(val) => this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.AGE_AT_MENOPAUSE, val)}
            value={this.props.stateManager.getPersonValue(this.getPersonId(), paths.AGE_AT_MENOPAUSE)}
          />
        </FormGroup>
      );
    }
    return null;
  }

  renderHRTUsed() {
    return (
      <div>
        <FormGroup
          label="Have you used hormone replacement therapy (HRT)?"
        >
          <RadioGroup
            onChange={(e) => {
              const { value } = e.target;
              this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.HRT_USAGE, value);
              if (value !== 'using' || value !== 'stoppedWithin5' || value !== 'stoppedMore5') {
                this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.HRT_TYPE, null);
              }
            }}
            selectedValue={ this.props.stateManager.getPersonValue(this.getPersonId(), paths.HRT_USAGE)}
          >
            <Radio label="Yes, currently using HRT" value="using" className='bp3-large' />
            <Radio label="Yes, stopped within last 5 years" value="stoppedWithin5" className='bp3-large' />
            <Radio label="Yes, stopped use 5 or more years ago" value="stoppedMore5" className='bp3-large' />
            <Radio label="No, never" value="N" className='bp3-large' />
            <Radio label="Unknown" value="unknown" className='bp3-large' />
          </RadioGroup>
        </FormGroup>
        {this.renderHRTTypes()}
      </div>
    );
  }

  renderHRTTypes() {
    const hrtUsage = this.props.stateManager.getPersonValue(this.getPersonId(), paths.HRT_USAGE);
    if (hrtUsage === 'using' || hrtUsage === 'stoppedWithin5' || hrtUsage === 'stoppedMore5') {
      return (
        <FormGroup
          label="Have you used hormone replacement therapy (HRT)?"
        >
          <RadioGroup
            onChange={(e) => {
              const { value } = e.target;
              this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.HRT_TYPE, value);
            }}
            selectedValue={ this.props.stateManager.getPersonValue(this.getPersonId(), paths.HRT_TYPE)}
          >
            <Radio label="Known estrogen monotherapy HRT" value="estrogen" className='bp3-large' />
            <Radio label="Known combined HRT" value="combined" className='bp3-large' />
            <Radio label="Other type of HRT" value="other" className='bp3-large' />
            <Radio label="Unknown type of HRT" value="unknown" className='bp3-large' />
          </RadioGroup>
        </FormGroup>
      );
    }
    return null;
  }

  renderTobaccoUsed() {
    return (
      <div>
        <FormGroup
          label="Have you ever smoked tobacco?"
        >
          <RadioGroup
            onChange={(e) => {
              const { value } = e.target;
              this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.TOBACCO_USAGE, value);
              if (value !== 'currently' || value !== 'previously') {
                this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.AVERAGE_CIGARETTES_DAY, null)
                this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.TOBACCO_TOTAL_YEARS, null)
              }
            }}
            selectedValue={
              this.props.stateManager.getPersonValue(this.getPersonId(), paths.TOBACCO_USAGE)
            }
          >
            <Radio label="Yes, currently" value="currently" className='bp3-large' />
            <Radio label="Yes, previously" value="previously" className='bp3-large' />
            <Radio label="No" value="no" className='bp3-large' />
          </RadioGroup>
        </FormGroup>
        {this.renderTobaccoQuestions()}
      </div>
    );
  }

  renderTobaccoQuestions() {
    const tobaccoUsage = this.props.stateManager.getPersonValue(this.getPersonId(), paths.TOBACCO_USAGE);
    if (tobaccoUsage === 'currently' || tobaccoUsage === 'previously') {
      return (
        <>
          <FormGroup label={"Average cigarettes per day"}>
            <NumericInput
              onValueChange={(val) => this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.AVERAGE_CIGARETTES_DAY, val)}
              value={this.props.stateManager.getPersonValue(this.getPersonId(), paths.AVERAGE_CIGARETTES_DAY)}
            />
          </FormGroup>
          <FormGroup label={"Total Years"}>
            <NumericInput
              onValueChange={(val) => this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.TOBACCO_TOTAL_YEARS, val)}
              value={this.props.stateManager.getPersonValue(this.getPersonId(), paths.TOBACCO_TOTAL_YEARS)}
            />
          </FormGroup>
        </>
      );
    }
    return null;
  }

  renderAlcoholUsed() {
    return (
      <ConditionalSection
        // label={ getLabel(this.context.localization, paths.GENETIC_TESTING_PERFORMED, 'patient') }
        label={ 'Do you drink alcohol?' }
        showWhenCondition='Y'
        conditionState={this.props.stateManager.getPersonValue(this.getPersonId(), paths.ALCOHOL_USAGE) ? 'Y' : 'N'}
        onConditionStateChange={(e) => {
          const alcoholUsage = e.target.value === 'Y';
          this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.ALCOHOL_USAGE, alcoholUsage);
        }}
        orderSchema={['Y', 'N']}
        cleanUpChildQuestions={ () => {
          this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.DRINKING_FRECUENCY, null);
          this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.DRINK_AMOUNT, null);
        }}
      >
        <FormGroup label={"On average, how many drinks do you have each month?"}>
          <NumericInput
            onValueChange={(val) => {
              this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.DRINKING_FRECUENCY, 'monthly');
              this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.DRINK_AMOUNT, val);
            }}
            value={this.props.stateManager.getPersonValue(this.getPersonId(), paths.DRINK_AMOUNT)}
          />
        </FormGroup>
      </ConditionalSection>
    );
  }

  renderEndometriosisDiagnosed() {
    return (
      <ConditionalSection
        // label={ getLabel(this.context.localization, paths.GENETIC_TESTING_PERFORMED, 'patient') }
        label={ 'Have you ever been diagnosed with endometriosis?' }
        showWhenCondition='Y'
        conditionState={this.props.stateManager.getPersonValue(this.getPersonId(), paths.ENDOMETRIOSIS_DIAGNOSED)}
        onConditionStateChange={(e) => this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.ENDOMETRIOSIS_DIAGNOSED, e.target.value)}
        orderSchema={['Y', 'N', 'U']}
        cleanUpChildQuestions={ () => null }
      >
      </ConditionalSection>
    );
  }

  renderFITStatus() {
    const fitDiagnostic = this.getDiagnosticTest('fit');

    return (
      <ConditionalSection
        // label={ getLabel(this.context.localization, paths.GENETIC_TESTING_PERFORMED, 'patient') }
        label={ 'Have you ever had a fecal immunochemical test (also known as FIT)?' }
        showWhenCondition='Y'
        conditionState={fitDiagnostic.isPerformed}
        onConditionStateChange={(e) => {
          const index = this.getDiagnosticTestIndex('fit');
          this.props.stateManager.removeFromArrayInPath(this.getPersonId(), paths.DIAGNOSTIC_TESTS, index);
          this.props.stateManager.addToPersonArray(this.getPersonId(), paths.DIAGNOSTIC_TESTS, {
            type: 'fit',
            isPerformed: e.target.value,
            result: null
          });
        }}
        orderSchema={['Y', 'N', 'U']}
        cleanUpChildQuestions={() => null}
      >
        <FormGroup
          label="What was the result?"
        >
          <RadioGroup
            onChange={(e) => {
              const index = this.getDiagnosticTestIndex('fit');
              this.props.stateManager.removeFromArrayInPath(this.getPersonId(), paths.DIAGNOSTIC_TESTS, index);
              this.props.stateManager.addToPersonArray(this.getPersonId(), paths.DIAGNOSTIC_TESTS, {
                type: 'fit',
                isPerformed: 'Y',
                result: e.target.value
              });
              // this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.FIT_RESULT, e.target.value);
            }}
            selectedValue={ fitDiagnostic.result }
          >
            <Radio label="Normal" value="normal" className='bp3-large' />
            <Radio label="Abnormal" value="abnormal" className='bp3-large' />
            <Radio label="No result or unsatisfactory" value="noResult" className='bp3-large' />
          </RadioGroup>
        </FormGroup>
      </ConditionalSection>
    );
  }

  getDiagnosticTest(type) {
    const diagnosticTests = this.props.stateManager.getPersonValue(this.getPersonId(), paths.DIAGNOSTIC_TESTS);
    const requiredDiagnosticTest = _.find(diagnosticTests, { type });

    return requiredDiagnosticTest || {};
  }

  getDiagnosticTestIndex(type) {
    const diagnosticTests = this.props.stateManager.getPersonValue(this.getPersonId(), paths.DIAGNOSTIC_TESTS);
    const index = _.findIndex(diagnosticTests, { type: type });

    return index;
  }

  renderColonoscopyStatus() {
    const colonoscopyDiagnostic = this.getDiagnosticTest('colonoscopy');

    return (
      <ConditionalSection
        // label={ getLabel(this.context.localization, paths.GENETIC_TESTING_PERFORMED, 'patient') }
        label={ 'Have you ever had a colonoscopy?' }
        showWhenCondition='Y'
        conditionState={colonoscopyDiagnostic.isPerformed}
        onConditionStateChange={(e) => {
          const index = this.getDiagnosticTestIndex('colonoscopy');
          this.props.stateManager.removeFromArrayInPath(this.getPersonId(), paths.DIAGNOSTIC_TESTS, index);
          this.props.stateManager.addToPersonArray(this.getPersonId(), paths.DIAGNOSTIC_TESTS, {
            type: 'colonoscopy',
            isPerformed: e.target.value,
            result: null
          });
        }}
        orderSchema={['Y', 'N', 'U']}
        cleanUpChildQuestions={() => null}
      >
        <FormGroup label={"How old were you when you started having colonscopies?"}>
          <NumericInput
            onValueChange={() => null}
            value={null}
          />
        </FormGroup>
      </ConditionalSection>
    );
  }

  renderMammogramStatus() {
    return (
      <ConditionalSection
        // label={ getLabel(this.context.localization, paths.GENETIC_TESTING_PERFORMED, 'patient') }
        label={ 'Have you ever had a mammogram?' }
        showWhenCondition='Y'
        conditionState={this.props.stateManager.getPersonValue(this.getPersonId(), paths.MAMMOGRAM_STATUS)}
        onConditionStateChange={(e) => this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.MAMMOGRAM_STATUS, e.target.value)}
        orderSchema={['Y', 'N', 'U']}
        cleanUpChildQuestions={ () => null }
      >
      </ConditionalSection>
    );
  }

  renderBreastBiopsyStatus() {
    return (
      <ConditionalSection
        // label={ getLabel(this.context.localization, paths.GENETIC_TESTING_PERFORMED, 'patient') }
        label={ 'Have you ever had a breast biopsy?' }
        showWhenCondition='Y'
        conditionState={this.props.stateManager.getPersonValue(this.getPersonId(), paths.BREAST_BIOPSY_STATUS)}
        onConditionStateChange={(e) => this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.BREAST_BIOPSY_STATUS, e.target.value)}
        orderSchema={['Y', 'N', 'U']}
        cleanUpChildQuestions={ () => this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.BREAST_BIOPSY_RESULTS, null) }
      >
        <MultipleConditionalSection
          label={ 'What were the results?' }
          showWhenCondition={CONDITIONS.ALL}
          options={[
            { id: 'benign', name: 'Benign (normal)' },
            { id: 'lobular', name: 'Lobular carcinoma in situ (LCIS)' },
            { id: 'atypical', name: 'Atypical hyperplasia' },
            { id: 'hyperplasia', name: 'Hyperplasia (not atypical)' },
            { id: 'unknown', name: 'Result unknown' }
          ]}
          onChange={() => null}
        ></MultipleConditionalSection>
      </ConditionalSection>
    );
  }

  renderProstateBiopsyStatus() {
    const prostateBiopsyDiagnostic = this.getDiagnosticTest('prostateBiopsy');

    return (
      <ConditionalSection
        // label={ getLabel(this.context.localization, paths.GENETIC_TESTING_PERFORMED, 'patient') }
        label={ 'Have you ever had a prostate biopsy?' }
        showWhenCondition='Y'
        conditionState={prostateBiopsyDiagnostic.isPerformed}
        onConditionStateChange={(e) => {
          const index = this.getDiagnosticTestIndex('prostateBiopsy');
          this.props.stateManager.removeFromArrayInPath(this.getPersonId(), paths.DIAGNOSTIC_TESTS, index);
          this.props.stateManager.addToPersonArray(this.getPersonId(), paths.DIAGNOSTIC_TESTS, {
            type: 'prostateBiopsy',
            isPerformed: e.target.value,
            result: null
          });
        }}
        orderSchema={['Y', 'N', 'U']}
        cleanUpChildQuestions={ () => null }
      >
      </ConditionalSection>
    );
  }

  renderElevatedPSAStatus() {
    const elevatedPSADiagnostic = this.getDiagnosticTest('elevatedPSA');

    return (
      <ConditionalSection
        // label={ getLabel(this.context.localization, paths.GENETIC_TESTING_PERFORMED, 'patient') }
        label={ 'Have you ever had elevated PSA (prostate-specific antigen)?' }
        showWhenCondition='Y'
        conditionState={elevatedPSADiagnostic.isPerformed}
        onConditionStateChange={(e) => {
          const index = this.getDiagnosticTestIndex('elevatedPSA');
          this.props.stateManager.removeFromArrayInPath(this.getPersonId(), paths.DIAGNOSTIC_TESTS, index);
          this.props.stateManager.addToPersonArray(this.getPersonId(), paths.DIAGNOSTIC_TESTS, {
            type: 'elevatedPSA',
            isPerformed: e.target.value,
            result: null
          });
        }}
        orderSchema={['Y', 'N', 'U']}
        cleanUpChildQuestions={ () => null }
      >
      </ConditionalSection>
    );
  }

  renderFemaleSurgeries() {
    const surgeries = [
      { id: 'lumpectomy', name: 'Lumpectomy (part of breast removed)' },
      { id: 'mastectomy', name: 'Mastectomy (entire breast removed)' },
      { id: 'hysterectomy', name: 'Hysterectomy (uterus removed)' },
      { id: 'tubalLigation', name: 'Tubal ligation (tubes tied)' },
      { id: 'oophorectomy', name: 'Oophorectomy (ovary removed)' },
      { id: 'salpingectomy', name: 'Salpingectomy (fallopian tubes removed)' },
    ];
    const storedSurgeries = this.props.stateManager.getPersonValue(this.getPersonId(), paths.SURGERIES);

    const handleSurgeryChange = (surgery) => () => {
      const surgeryIndex = _.findIndex(storedSurgeries, { type: surgery.id });

      if (surgeryIndex >= 0) {
        this.props.stateManager.removeFromArrayInPath(this.getPersonId(), paths.SURGERIES, surgeryIndex);
      } else {
        this.props.stateManager.addToPersonArray(this.getPersonId(), paths.SURGERIES, {
          type: surgery.id,
          lateralityType: null
        });
      }
    }

    return (
      <FormGroup label={'Have you ever had any of the following surgeries?'}>
        {surgeries.map(surgery => {
          const isChecked = _.includes(_.map(storedSurgeries, 'type'), surgery.id);
          return (
            <div key={surgery.id}>
              <Checkbox
                checked={isChecked}
                large={true}
                label={surgery.name}
                onChange={handleSurgeryChange(surgery)}
              />
              {this.renderSurgerySide(surgery, isChecked, storedSurgeries)}
            </div>
          )
        })}
      </FormGroup>
    );
  }

  renderSurgerySide(surgery, isChecked, storedSurgeries) {
    const allowedSurgeries = ['lumpectomy', 'mastectomy', 'salpingectomy', 'oophorectomy'];
    if (isChecked && _.includes(allowedSurgeries, surgery.id)) {
      const selectedValue = _.find(storedSurgeries, { type: surgery.id });

      return (
        <RadioGroup
          label={'One or both [breasts, ovaries, fallopian tubes]?'}
          onChange={(e) => {
            const surgeryIndex = _.findIndex(storedSurgeries, { type: surgery.id });
            this.props.stateManager.removeFromArrayInPath(this.getPersonId(), paths.SURGERIES, surgeryIndex);
            this.props.stateManager.addToPersonArray(this.getPersonId(), paths.SURGERIES, {
              type: surgery.id,
              lateralityType: e.target.value
            });
          }}
          selectedValue={selectedValue.lateralityType}
          className='bp3-form-group'
        >
          <Radio label={ 'Left' } value='left' className='bp3-large' />
          <Radio label={ 'Right' } value='right' className='bp3-large' />
          <Radio label={ 'Both' } value='bothSides' className='bp3-large' />
        </RadioGroup>
      );
    }
    return null;
  }

  renderGenesTestStatus() {
    const geneticTestingPerformed = this.props.stateManager.getPersonValue(this.getPersonId(), paths.GENETIC_TESTING_PERFORMED);
    const genes = [
      { id: 'brca1', name: 'BRCA1' },
      { id: 'brca2', name: 'BRCA2' },
      { id: 'palb2', name: 'PALB2' },
      { id: 'check2', name: 'CHECK2' },
      { id: 'atm', name: 'ATM' },
      { id: 'other', name: 'Other' },
    ];

    if (geneticTestingPerformed === 'Y') {
      const storedGenes = this.props.stateManager.getPersonValue(this.getPersonId(), paths.GENE_TESTS);

      const handleGeneChange = (gene) => () => {
        const geneIndex = _.findIndex(storedGenes, { nameOfGeneTest: gene.id });
  
        if (geneIndex >= 0) {
          this.props.stateManager.removeFromArrayInPath(this.getPersonId(), paths.GENE_TESTS, geneIndex);
        } else {
          this.props.stateManager.addToPersonArray(this.getPersonId(), paths.GENE_TESTS, {
            nameOfGeneTest: gene.id,
            geneTestResult: 'positive'
          });
        }
      }

      return (
        <ConditionalSection
          // label={ getLabel(this.context.localization, paths.GENETIC_TESTING_PERFORMED, 'patient') }
          label={ 'Did you test positive for any genes?' }
          showWhenCondition='Y'
          conditionState={this.state.isPositiveForGenes}
          onConditionStateChange={(e) => this.setState({ isPositiveForGenes: e.target.value })}
          orderSchema={['Y', 'N', 'U']}
          cleanUpChildQuestions={() => {
            const allGenes = this.props.stateManager.getPersonValue(this.getPersonId(), paths.GENE_TESTS);
            allGenes.forEach((_) =>
              this.props.stateManager.removeFromArrayInPath(this.getPersonId(), paths.GENE_TESTS, 0));
          }}
        >
          <FormGroup label={'Which gene(s)?'}>
            {genes.map(gene => {
              const isChecked = _.includes(_.map(storedGenes, 'nameOfGeneTest'), gene.id);
              return (
                <div key={gene.id}>
                  <Checkbox
                    checked={isChecked}
                    large={true}
                    label={gene.name}
                    onChange={handleGeneChange(gene)}
                  />
                </div>
              )
            })}
          </FormGroup>
        </ConditionalSection>
      );
    }
  }

  renderAdoptionInfo(targetPerson, type) {
    // const label = getLabel(this.context.localization, paths.ADOPTED, 'patient');
    let label = 'Are you adopted?';
    if (targetPerson === 'mother') {
      label = 'Is your mother adopted?';
    } else if (targetPerson === 'father') {
      label = 'Is your father adopted?';
    }

    return (
      <>
        <ConditionalSection
          // label={ getLabel(this.context.localization, paths.GENETIC_TESTING_PERFORMED, 'patient') }
          label={label}
          showWhenCondition='Y'
          conditionState={this.props.stateManager.getPersonValue(this.getPersonId(), paths.ADOPTED_STATUS)}
          onConditionStateChange={(e) => this.props.stateManager.setPersonValue(this.resolvePersonIdWithCreate, paths.ADOPTED_STATUS, e.target.value)}
          orderSchema={['Y', 'N']}
          cleanUpChildQuestions={ () => null }
        >
          {type === 'proband' && 
            <Callout intent="primary">
              <p className="text-size-sm m-0">
                The following questions relate to the health history of your biological relatives. You can skip the questions if you do not have this information.
              </p>
            </Callout>
          }
        </ConditionalSection>
      </>
    );
  }

  getAliveStatus() {
    const val = this.props.stateManager.getPersonValue(this.getPersonId(), paths.ALIVE_STATUS);
    if (val === false) {
      return "no";
    } else if (val) {
      return "yes";
    } else {
      return null;
    }
  }

  getPersonId() {
    return this.props.resolvePersonId(false, this.props.stateManager.cpt.state);
  }

  doesProbandHaveATwin = () => {
    const hasTwin = this.props.stateManager.getPersonValue(this.props.stateManager.getProband().id, paths.PROPERTY_IS_PRESENT, {propertyIdx: { [getFieldName(paths.PROPERTY_TYPE)]: 'twin' }})
    return hasTwin && hasTwin  === 'Y'
  }
}

export default PersonStep;