import React from 'react';
import Chooser from './Chooser.jsx';

function ConditionalSection({ 
  children,
  conditionState,
  cleanUpChildQuestions,
  label, 
  onConditionStateChange, 
  orderSchema, 
  showWhenCondition, 
}) {
  const renderChildren = () => {
    if (showWhenCondition === conditionState) {
      return children;
    } else {
      return null;
    }
  }  
  const onParentQuestionChange = (e) => {
    onConditionStateChange(e)
    if (e.target.value !== showWhenCondition) {
      cleanUpChildQuestions()
    }
  }
  return (
      <React.Fragment>
        <Chooser
          label={label}
          onChange={(e) => onParentQuestionChange(e)}
          selectedValue={conditionState}
          orderSchema={orderSchema}
          path={'yesNoUnknown'}
        />
        {renderChildren()}
      </React.Fragment>
    );
  }

export default ConditionalSection;