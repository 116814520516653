import React, { Component } from 'react';
import _ from 'lodash';

import MultiPersonEntry from '../widgets/MultiPersonEntry'
import LifeStatus from '../widgets/LifeStatus'
import HeartProblems from '../widgets/HeartProblems'
import FullName from '../widgets/FullName'

class ChildInfoStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      childPersonIds: this.getChildPersonIds(this.props.person.id)
    }
  }
  
  render() {
    return (
      <React.Fragment>
          <MultiPersonEntry
            personIds={this.state.childPersonIds}
            entryRenderer={this.renderChildEntry.bind(this)}
            personLabel={() => "Patient's Child"}
            stateManager={this.props.stateManager}
            getValue={this.props.stateManager.getPersonValue.bind(this.props.stateManager)}
          />
      </React.Fragment>
    );
  }
  
  renderChildEntry = (personId) => {
    return(
      <div key={personId}>
          <FullName
            id={personId}
            getValue={this.props.stateManager.getPersonValue.bind(this.props.stateManager)}
            setValue={this.props.stateManager.setPersonValue.bind(this.props.stateManager)}
            personIdWithCreate={personId}
          />
          <LifeStatus 
            personId={personId}
            personIdWithCreate={personId}
            stateManager={this.props.stateManager}
            />
          <HeartProblems 
            personId={personId}
            personIdWithCreate={personId}
            stateManager={this.props.stateManager}
            />
      </div>
    )
  }
  
  getChildPersonIds = (id) => {
    const children = ['M', 'F'].map((sex) => this.props.stateManager.getFamilyHelper().getChildren(id, sex))
    return _.flatten(children).map(child => child.id);
  }
}

export default ChildInfoStep;