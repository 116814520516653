import { Suggest } from '@blueprintjs/select';
import { MenuItem, Button, Intent, FormGroup } from '@blueprintjs/core';
import _ from 'lodash';
import React from 'react';
import { PersonFieldPaths } from '../utils/QuestionnaireStateManager.js';

function PersonSuggest({ onSelect, stateManager, personIds, labelText, selectedPersonId, labelSchema }) {
  const getPersonIds = () => {
    //If create person on click
    if(labelSchema){
      return personIds
    }
    // Else Filter people who have neither first name nor last name set
    return _.filter(personIds, (personId) => {
      const person = stateManager.getPersonById(personId);
      return _.get(person, PersonFieldPaths.FIRST_NAME) || _.get(person, PersonFieldPaths.LAST_NAME);
    });
    
  }

  
  const getPersonLabelForPersonId = (personId) => {
    const person = stateManager.getPersonById(personId);
    //If person doesn't exist || they have no names entered refer to labelSchema
    if ((labelSchema && !person) || (!_.get(person, PersonFieldPaths.FIRST_NAME) && !_.get(person, PersonFieldPaths.LAST_NAME))){
      return labelSchema[personId].label
    }
    const UNKNOWN = '(unknown)';
    let personStr = _.get(person, PersonFieldPaths.FIRST_NAME, UNKNOWN);
    personStr += ' ';
    personStr += _.get(person, PersonFieldPaths.LAST_NAME, UNKNOWN);
    return personStr.trim();
  }

  const getPersonBirthdateLabel = (personId) => {
    //If person doesn't exist = refer to labelSchema
    //return
    const person = stateManager.getPersonById(personId);
    const birthDate = _.get(person, PersonFieldPaths.DATE_OF_BIRTH);
    return birthDate ? 'born ' + new Date(birthDate).toLocaleDateString() : null;
  }

  const itemPredicate = (query, personId) => {
    const person = stateManager.getPersonById(personId);
    const nameStr = _.get(person, PersonFieldPaths.FIRST_NAME, '') + _.get(person, PersonFieldPaths.LAST_NAME, '');
    return nameStr.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  }

  const itemRenderer = (personId, { handleClick, modifiers }) => (
    <MenuItem
      active={modifiers.active}
      key={personId}
      onClick={handleClick}
      text={getPersonLabelForPersonId(personId)}
      shouldDismissPopover={false}
      label={getPersonBirthdateLabel(personId)}
    /> 
  );

  const clearButton = (
    <Button
      icon="delete"
      minimal={true}
      intent={Intent.DANGER}
      onClick={() => onSelect(null)}
    />
  );

  return (
    <FormGroup
      // helperText="Helper text with details..."
      label={labelText}
      labelFor="text-input"
      // labelInfo="(required)"
      >
      <Suggest
        className="bp3-form-content"
        id="person-suggest"
        inputValueRenderer={(personId) => getPersonLabelForPersonId(personId)}
        itemRenderer={itemRenderer}
        items={getPersonIds()}
        onItemSelect={onSelect}
        selectedItem={selectedPersonId}
        itemPredicate={itemPredicate}
        popoverProps={{
          minimal: true,
        }}
        fill={true}
        noResults={<MenuItem disabled={true} text="No results" />}
        inputProps={{
          rightElement: clearButton
        }}
      />
    </FormGroup>
  );
}

export default PersonSuggest;