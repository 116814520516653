import { FormGroup, NumericInput } from "@blueprintjs/core";
import * as CancerTypes from "../utils/CancerTypes.js";
import React, { useState } from "react";
import DropdownSelector from "./DropdownSelector.jsx";
import ConditionalSection from "../widgets/ConditionalSection";
import { PersonFieldPaths as paths } from "../utils/QuestionnaireStateManager.js";
import _ from "lodash";

export default function SpecificCancerQuestions({
  option,
  personId,
  onSelectedType,
  onQualifiers,
  stateManager
}) {
  const [ageDiagnosis1, setAgeDiagnosis1] = useState();
  const [ageDiagnosis2, setAgeDiagnosis2] = useState();
  const [secondBreastCancer, setSecondBreastCancer] = useState();
  const [selType, setSelType] = useState();

  const getCancerTypes = () => {
    switch (option.name) {
      case "Breast":
        return CancerTypes.BREAST_TYPES;
      case "Ovarian":
        return CancerTypes.OVARIAN_TYPES;
      case "Skin":
        return CancerTypes.SKIN_TYPES;
      case "Kidney and urinary tract":
        return CancerTypes.KIDNEY_AND_URINARY_TYPES;
      case "Brain":
        return CancerTypes.BRAIN_TYPES;
      case "Colon":
        return CancerTypes.COLON_TYPES;
      case "Other":
        return CancerTypes.OTHER_TYPES;
      default:
        return null;
    }
  };

  const filterCancerTypes = (types) => {
    let selectedCancers = stateManager.getPersonValue(personId, paths.CANCERS);
    return types.filter((category) => {
      let isCancersSelected = _.findIndex(selectedCancers, item => {return item.id === category.id});
      return isCancersSelected === -1;
    });
  };

  const renderCancerTypes = () => {
    if (getCancerTypes(option.name)) {
      return (
        <FormGroup label="What type?">
          <DropdownSelector
            defaultLabel="Select type"
            items={filterCancerTypes(getCancerTypes(option.name))}
            onItemSelect={(e) => {
              onSelectedType(e);
              setSelType(e);
            }}
            key={option.id}
          ></DropdownSelector>
        </FormGroup>
      );
    }
  };

  const renderBreastSideSelect = () => {
    if (option.name === "Breast") {
      return (
        <React.Fragment>
          <FormGroup label="Which side of the body?">
            <DropdownSelector
              defaultLabel="Select side"
              items={CancerTypes.BREAST_LATERALITY}
              onItemSelect={(e) => onQualifiers(0, { laterality: e.id })}
            ></DropdownSelector>
          </FormGroup>
          {renderSecondOcurrence()}
        </React.Fragment>
      );
    }
  };

  const renderSecondOcurrence = () => {
    if (selType && selType.name === "Breast cancer") {
      return (
        <ConditionalSection
          label="Have you had more than one breast cancer?"
          showWhenCondition="Y"
          conditionState={secondBreastCancer}
          onConditionStateChange={(e) => {
            setSecondBreastCancer(e.target.value);
          }}
          orderSchema={["Y", "N", "U"]}
          cleanUpChildQuestions={() => onQualifiers(1)}
        >
          <FormGroup label="Which side of the body?">
            <DropdownSelector
              defaultLabel="Select side"
              items={CancerTypes.BREAST_LATERALITY}
              onItemSelect={(e) => onQualifiers(1, { laterality: e.id })}
            ></DropdownSelector>
          </FormGroup>
          <FormGroup label="Age at second diagnosis?">
            <NumericInput
              onValueChange={(e) => {
                onQualifiers(1, {
                  numericAgeAtDiagnosis: e,
                });
                setAgeDiagnosis2(e);
              }}
              value={ageDiagnosis2}
              minorStepSize={1}
              min={0}
              max={120}
            />
          </FormGroup>
        </ConditionalSection>
      );
    }
  };

  return (
    <div>
      {renderCancerTypes()}
      <FormGroup label="Age at diagnosis?">
        <NumericInput
          onValueChange={(e) => {
            onQualifiers(0, {
              numericAgeAtDiagnosis: e,
            });
            setAgeDiagnosis1(e);
          }}
          value={ageDiagnosis1}
          minorStepSize={1}
          min={0}
          max={120}
        />
      </FormGroup>
      {renderBreastSideSelect()}
    </div>
  );
}
