import React, { useContext } from 'react';
import { FormGroup, RadioGroup, Radio } from '@blueprintjs/core';
import Context from '../utils/context/Context'
import { getLabel, getOptions } from '../utils/helpers';

function Chooser({ label, onChange, selectedValue, orderSchema, path, patient }) {
  const { localization } = useContext(Context);
    return (
      <React.Fragment>
        <FormGroup
          label={label || getLabel(localization, path) }
        >
          <RadioGroup
            onChange={onChange}
            selectedValue={selectedValue}
          >
            { 
              getOptions(localization, path, orderSchema, patient).map(property => (
                <Radio label={property[1]} value={property[0]} key={property[0]} className='bp3-large' />
              ))
            }
          </RadioGroup>
        </FormGroup>
      </React.Fragment>
    );
  }

export default Chooser;