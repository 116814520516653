import React from 'react'
import { Toaster, Toast } from "@blueprintjs/core";

function AlertBar(props) {
  return (
    <Toaster>
      {props.toasts.map((toast, i) => {
         return <Toast
          key={i}
          message={toast.message}
          intent={toast.intent}
          timeout={7000}
          onDismiss={() => props.removeToast(i)}
        />
      })}
    </Toaster>
  )
}
export default AlertBar;

