import React, { useState, useEffect } from 'react';

import { Router, navigate } from "@reach/router"

import Questionnaire from './Questionnaire.jsx';
import AlertBar from './widgets/AlertBar';
import Home from './Home'

import './App.css';

import Context from './utils/context/Context'
import request from 'superagent';

import { getErrorMessage } from './utils/helpers'
import { login, getAccessToken } from './utils/persistance.js'
import ThankYou from './steps/ThankYou.jsx';
import SessionTimeout from './SessionTimeout.js';

function App() {
  const [demoMode, setDemoMode] = useState(true);
  const [loading, setLoading] = useState({});
  const [toasts, setToasts] = useState([])
  const [localization, setLocalization] = useState({})
  const [fatalErrorMessage, setFatalErrorMessage] = useState(null)
  const [dialogBox, setDialogBox] = useState({ show: false })
  const [loggingIn, setLoggingIn] = useState(true)
  const [isAuthenticated, setAuthenticated] = useState(false);


  const addToast = (statusId, intent, message) => {
    const toast = {
      id: statusId,
      intent,
      message
    }
    setToasts([...toasts, toast])
  }


  const getLocalizationFile = () => {
    setLoading({ ...loading, localization: true })
    request
      .get('/api/v1/config/localization')
      .then(res => {
        setLocalization(res.body)
        setLoading({ ...loading, localization: false })
      })
      .catch(err => {
        console.log('Localization file wont load', err)
        setFatalErrorMessage(getErrorMessage(err))
        setLoading({ ...loading, localization: false })
      });
  }

  useEffect(() => {
    login(() => {
      navigate('/questionnaire', { state: { fromRedirect: true }, replace: true });
      setAuthenticated(true);
      getLocalizationFile();
    }, (err) => {
      setAuthenticated(false);
      if (getAccessToken() !== '') {
        setFatalErrorMessage(getErrorMessage(err));
      }
      setLoggingIn(false);
    })
    // eslint-disable-next-line
  }, [])

  return (
    <Context.Provider value={{ loading, setLoading, demoMode, localization, setDemoMode, dialogBox, setDialogBox, setLoggingIn, isAuthenticated, setAuthenticated }}>
      <div className="App">
        <AlertBar
          toasts={toasts}
          removeToast={(i) => {
            const newToasts = [...toasts]
            newToasts.splice(i, 1)
            setToasts(newToasts)
          }
          }
        />
        <SessionTimeout />
        <Router>
          <Home default path="/" fatalErrorMessage={fatalErrorMessage} loggingIn={loggingIn} />
          <Questionnaire path="/questionnaire" id="questionnaire" addToast={addToast} />
          <ThankYou path="/thankyou" />
        </Router>
      </div>
    </Context.Provider>
  );
}

export default App;
